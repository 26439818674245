import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { Fragment } from "react";

function CustomMenu({ edit, editDisabled = false }) {
  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className="rounded-sm p-1 text-sm font-bold shadow-md hover:bg-primary/20">
        <DotsVerticalIcon className="h-4 w-4 text-primary" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-28 origin-top-right divide-y divide-gray-100 overflow-hidden rounded bg-white text-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-primary/20 font-semibold text-primary" : ""
                } group flex w-full items-center rounded-sm px-3 py-2 text-sm ${
                  editDisabled && "cursor-not-allowed"
                }`}
                disabled={editDisabled}
                onClick={edit}
              >
                Edit
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default CustomMenu;
