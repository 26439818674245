import { Link, NavLink } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const NotFoundPage = () => {
  return (
    <div
      style={{
        padding: "1rem",
      }}
    >
      <NavLink to="/" className="no-underline">
        Concert<strong>Rx</strong>
      </NavLink>
      <p className="mt-2 mb-4">⚠️ 404 | Page Not Found</p>
      <Link
        to="/"
        className="flex max-w-max items-center border px-3 py-2 shadow-sm hover:bg-gray-50"
      >
        <ArrowLeftIcon className="mr-2 h-4 w-4" />
        Go to Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
