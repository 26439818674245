import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useBoolean from "../../hooks/useBoolean";
import useStoreDetails from "../../hooks/useStoreDetails";
import { useAuth } from "../../utils/auth";
import { USER_ROLES } from "../../utils/constants";
import { classNames, showErrorToast } from "../../utils/helpers";
import { edit } from "../../utils/mutations";
import { getStaffByStoreId, getTeamsByStoreId } from "../../utils/queries";
import AddNewCard from "../add-new-card";
import CustomPopver from "../custom-popover";
import FormDialog from "../dialogs/form-dialog";
import InputGroup from "../input-group";
import Selector from "../selector";
import Spinner from "../spinner";
import CustomMenu from "./custom-menu";

function StaffDetailsDialog({
  open,
  closeDialog,
  action = "add",
  onSubmit,
  loading = false,
  data,
  dropdownOptions,
  handleDelete = () => {},
}) {
  const [staffs, setStaffs] = useState([]);
  const [managers, setManagers] = useState([]);
  const editMode = action === "edit";
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    // Setting data for edit
    if (data) {
      reset(data);
      setStaffs(data.staffs.map(({ id }) => id));
      setManagers(data.managers.map(({ id }) => id));
    } else {
      resetForm();
    }
    // eslint-disable-next-line
  }, [data, open]);

  const resetForm = () => {
    reset({ name: "" });
    setStaffs([]);
    setManagers([]);
  };

  const submit = (data) => {
    onSubmit({
      data: {
        ...data,
        managers,
        staffs,
      },
      reset: resetForm,
    });
  };

  const deleteEntity = () => {
    if (editMode)
      handleDelete({
        data: data,
        reset: resetForm,
      });
  };

  return (
    <FormDialog
      open={open}
      onClose={closeDialog}
      title={editMode ? "Update Team" : "Add Team"}
      disableClose
    >
      <form onSubmit={handleSubmit(submit)}>
        <InputGroup
          title="Team name"
          autoFocus
          htmlFor="name"
          id="name"
          error={open && errors.name}
          placeholder="Enter team name"
          disabled={loading}
          register={() =>
            register("name", {
              required: {
                value: true,
                message: "Team name is required",
              },
            })
          }
        />
        <div className="w-full py-4">
          <Selector
            label="Staff"
            value={staffs}
            onChange={setStaffs}
            data={dropdownOptions?.staffs || []}
            nameKey="name"
            placeholder="Select staff"
            loading={loading}
          />
        </div>
        <div className="w-full py-4">
          <Selector
            label="Managers"
            value={managers}
            onChange={setManagers}
            data={dropdownOptions?.managers || []}
            placeholder="Select managers"
            loading={loading}
            nameKey="username"
          />
        </div>
        <div className="flex items-center justify-end pt-6">
          {editMode ? (
            <CustomPopver
              panelStyles="absolute bottom-10 -left-1/2 z-10 w-72 rounded-md bg-white shadow-md"
              buttonLabel="Delete"
              buttonStyles="btn-danger"
              loading={loading}
              onOk={(close) => {
                close();
                deleteEntity();
              }}
              onCancel={(close) => close()}
            />
          ) : null}
          <button
            className="btn-secondary mx-2 w-32"
            disabled={loading}
            onClick={(e) => {
              closeDialog();
              e.preventDefault();
            }}
          >
            Cancel
          </button>
          <button
            className="btn-primary ml-2 w-32"
            disabled={loading}
            type="submit"
          >
            {action === "edit" ? "Update" : "Add"}
          </button>
        </div>
      </form>
    </FormDialog>
  );
}

function TeamCard({ name, setSelectedTeam, id, staffs, managers }) {
  const _edit = () => {
    setSelectedTeam(id);
  };

  return (
    <div className="py-4 pr-8">
      <div className="mx-auto w-96 overflow-hidden rounded-md shadow-lg">
        <div className="relative p-6 pt-16 pb-8">
          <div
            className={classNames(
              "absolute left-0 top-0 rounded-br-md bg-cool-orange shadow-md"
            )}
          >
            <p className="w-36 p-3 text-center text-xs font-semibold text-white">
              Team
            </p>
          </div>
          <div className="absolute right-4 top-2">
            <CustomMenu edit={_edit} />
          </div>
          <div className="ml-4 flex items-center py-3">
            <h4 className="ellipsis text-base font-semibold" title={name}>
              {name}
            </h4>
          </div>
          <div className="ml-4 flex flex-wrap items-center">
            {staffs.map(({ name, id, color }) => (
              <span
                key={id}
                className="my-2 mr-3 rounded-full py-[0.375rem] px-4 text-xs font-semibold text-white"
                style={{ background: color || "#000" }}
              >
                {name}
              </span>
            ))}
            {managers.map(({ username, email, id, color }) => (
              <span
                key={id}
                className="my-2 mr-3 rounded-full py-[0.375rem] px-4 text-xs font-semibold text-white"
                style={{ background: color || "#000" }}
              >
                {username || email}
              </span>
            ))}
          </div>
          <div className="ml-20"></div>
        </div>
      </div>
    </div>
  );
}

function Teams() {
  const storeDetails = useStoreDetails();

  const [open, setOpen] = useBoolean();
  const [selectedTeam, setSelectedTeam] = useState(null);

  const { role } = useAuth();

  const {
    data: queryData,
    isLoading: queryLoading,
    isFetching: queryFetching,
    refetch,
  } = useQuery(["/teams", { storeId: storeDetails?.id }], getTeamsByStoreId, {
    enabled: Boolean(storeDetails?.id),
  });

  const { data: staffData, isLoading: staffQueryLoading } = useQuery(
    ["/staff", { storeId: storeDetails?.id }],
    getStaffByStoreId,
    {
      enabled: Boolean(storeDetails?.id),
    }
  );

  const { mutateAsync: updateTeam, isLoading: teamUpdateLoading } = useMutation(
    edit,
    {
      onError: showErrorToast,
    }
  );

  const loading =
    queryLoading || staffQueryLoading || queryFetching || teamUpdateLoading;

  const notStaffMember = [
    USER_ROLES.HEADOFFICE_ADMIN,
    USER_ROLES.HEADOFFICE_MANAGER,
    USER_ROLES.STORE_MANAGER,
  ].includes(role);

  const data = useMemo(() => {
    return (
      queryData?.data?.map(({ name, ...d }) => ({
        ...d,
        name: name || "-",
      })) || []
    );
  }, [queryData]);

  const dataForDialog = data.find(({ id }) => id === selectedTeam);

  useEffect(() => {
    if (selectedTeam) {
      setOpen.on();
    }
    // eslint-disable-next-line
  }, [selectedTeam]);

  const openDialog = () => {
    setOpen.on();
  };

  const closeDialog = () => {
    if (!loading) {
      setOpen.off();
      setSelectedTeam(null);
    }
  };

  const onSubmit = async ({ data, reset }) => {
    try {
      let { name, managers, staffs, id } = data;
      if (!storeDetails?.id) return;
      let url = selectedTeam ? `/teams/${id}` : "/teams";
      let method = selectedTeam ? "PUT" : "POST";
      let _data = {
        store: {
          id: storeDetails.id,
        },
        name,
        managers,
        staffs,
      };
      await updateTeam({
        method,
        url,
        data: _data,
      });
      toast.success(
        selectedTeam ? "Updated team successfully" : "Created new team"
      );
      await refetch();
      reset();
      closeDialog();
    } catch (error) {}
  };

  const handleDelete = async ({ data, reset }) => {
    try {
      let { id } = data;
      await updateTeam({
        method: "DELETE",
        url: `/teams/${id}`,
      });
      toast.success("Deleted team");
      await refetch();
      reset();
      closeDialog();
    } catch (error) {}
  };

  return (
    <>
      <StaffDetailsDialog
        open={open}
        closeDialog={closeDialog}
        onSubmit={onSubmit}
        loading={loading}
        data={dataForDialog || null}
        action={dataForDialog ? "edit" : "add"}
        handleDelete={handleDelete}
        dropdownOptions={{
          staffs: staffData?.staff,
          managers: staffData?.managers,
        }}
      />
      <h2 className="title mb-2">Teams</h2>
      <div className="mt-2 flex flex-wrap">
        {queryLoading ? (
          <Spinner className="mx-auto mt-32 h-10 w-10" />
        ) : (
          <>
            {data.map((d, idx) => (
              <TeamCard {...d} key={idx} setSelectedTeam={setSelectedTeam} />
            ))}
            {notStaffMember && (
              <AddNewCard
                onClick={openDialog}
                disabled={loading}
                innerCardStyles="py-7"
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Teams;
