import React from "react";
import { classNames } from "../utils/helpers";

function InputGroup({
  register = () => {},
  error,
  htmlFor,
  type = "text",
  id,
  autoFocus = false,
  placeholder,
  title,
  inputStyles = "",
  disabled = false,
  labelStyles = "",
  className = "",
  ...otherInputProps
}) {
  return (
    <div className={classNames("w-full py-4", className)}>
      <div className="input-group relative">
        <input
          type={type}
          id={id}
          autoFocus={autoFocus}
          className={`${classNames(
            "w-full rounded-md border !border-black/30 py-3 px-5 text-sm",
            error && "border-error",
            disabled && "cursor-not-allowed border-black/20 text-black/60",
            inputStyles
          )}`}
          placeholder={placeholder}
          {...register()}
          disabled={disabled}
          {...otherInputProps}
        />
        <label
          htmlFor={htmlFor}
          className={classNames("input-label", labelStyles)}
        >
          {title}
        </label>
        {error && (
          <small className="ml-5 text-xs text-error">{error.message}</small>
        )}
      </div>
    </div>
  );
}

export default InputGroup;
