export const FEILDS = {
  subject: {
    name: "title",
    validation: { required: true },
  },
  description: {
    name: "description",
  },
  startTime: {
    name: "start",
    validation: { required: true },
  },
  endTime: {
    name: "end",
    validation: { required: true },
  },
  recurrenceRule: {
    name: "repeat",
  },
};

export const ASSIGNED_OPTIONS = {
  ALL: {
    text: "All",
    value: "all",
  },
  STAFF: {
    text: "Staff",
    value: "staff",
  },
  TEAMS: {
    text: "Teams",
    value: "teams",
  },
};

export const ADMIN_ASSIGNED_OPTIONS = {
  ...ASSIGNED_OPTIONS,
  STORES: {
    text: "Stores",
    value: "stores",
  },
};

export const COLORS = {
  primary: "#1890FF",
  error: "#EF3935",
  success: "#44D600",
  "light-gray": "#F4F5F6",
  "cool-gray": "#8B93A5",
  "dark-blue": "#223354",
  "cool-orange": "#FFAF36",
};

export const USER_COLOR_OPTIONS = [
  "#00b7ff",
  "#00adff",
  "#00ffff",
  "#826400",
  "#580041",
  "#ff00ff",
  "#00ff00",
  "#cf00ff",
  "#b4ffd7",
  "#ffca00",
  "#969600",
  "#b4a2ff",
  "#c20078",
  "#0000c1",
  "#ff8b00",
  "#ffc8ff",
  "#666666",
  "#ff0000",
  "#cccccc",
  "#009e8f",
  "#d7a870",
  "#8200ff",
  "#960000",
  "#bbff00",
  "#ffff00",
  "#006f00",
];

export const TASK_STATUS = {
  PENDING: { value: "PENDING", color: "#FFAA33" },
  COMPLETED: { value: "COMPLETED", color: "#32AD5B" },
  NOT_COMPLETED: { value: "NOT_COMPLETED", color: "#D63232" },
};
