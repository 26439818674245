import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AppLayout from "./layouts/app-layout";
import NotFoundPage from "./pages/404";
import HeadofficeOverview from "./pages/headoffice-overview";
import LoginRedirectPage from "./pages/login-redirect";
import Store from "./pages/store";
import StoreList from "./pages/store-list";
import StoreSettings from "./pages/store-settings";
import { AuthProvider } from "./utils/auth";
import { ThemeProvider } from "./utils/theme";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="connect/auth0/redirect" element={<LoginRedirectPage />} />
      <Route path="/" element={<AppLayout />}>
        <Route path="/store" element={<StoreList />} />
        <Route path="/overview" element={<HeadofficeOverview />} />
        <Route path="/store/:storeId" element={<Store />} />
        <Route path="/store/:storeId/settings" element={<StoreSettings />} />
        <Route path="/" element={<Navigate to="/store" replace />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ErrorFallback = ({ error }) => {
  return (
    <div className="flex min-h-screen w-screen flex-col items-center justify-center p-4">
      <div className="w-full max-w-3xl">
        <h2 className="mb-4 text-2xl text-red-600">Oops! Something Broke...</h2>
        <p className="mb-2">Send this to the devs so they can fix it!</p>
        <p className="text-sm text-gray-500">Error Message:</p>
        <pre className="mb-2 whitespace-pre-wrap border p-4 text-xs">
          {error.message}
        </pre>
        <p className="text-sm text-gray-500">Error Stack:</p>
        <pre className="mb-4 whitespace-pre-wrap border p-4 text-xs">
          {error.stack}
        </pre>
        <button
          className="btn-primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ThemeProvider>
                <AppRoutes />
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable={false}
                  pauseOnHover={false}
                  closeButton={false}
                />
              </ThemeProvider>
            </AuthProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </Router>
      </ErrorBoundary>
    </>
  );
};

export default App;
