import dayjs from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useBoolean from "../../hooks/useBoolean";
import { useAuth } from "../../utils/auth";
import { showErrorToast } from "../../utils/helpers";
import { deleteStore, updateStore } from "../../utils/mutations";
import { getHeadoffice } from "../../utils/queries";
import FormDialog from "../dialogs/form-dialog";
import StoreDetailsForm from "./store-details-form";

export default function StoreSettingsDialog({
  data,
  setData,
  createTag,
  tagsData,
  loading: isLoading,
}) {
  const [open, setOpen] = useBoolean();

  const { data: authData } = useAuth();
  const headoffice = authData?.manager?.headoffice;
  const { refetch } = useQuery(
    ["/headoffice", { headofficeId: headoffice?.id }],
    getHeadoffice,
    { enabled: Boolean(headoffice?.id) }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control,
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      name: "",
      address: "",
      ip: "",
      email: "",
      startTime: "",
      endTime: "",
      tags: [],
    },
  });

  const { mutate: update, isLoading: isLoadingMutation } = useMutation(
    updateStore,
    {
      onSuccess: async () => {
        await refetch();
        reset();
        closeDialog();
        toast.success("Updated store");
      },
      onError: showErrorToast,
    }
  );

  const { mutate: remove, isLoading: isLoadingDeletion } = useMutation(
    deleteStore,
    {
      onSuccess: async () => {
        await refetch();
        reset();
        closeDialog();
        toast.success("Deleted store");
      },
      onError: showErrorToast,
    }
  );

  const loading = isLoadingMutation || isLoadingDeletion || isLoading;

  useEffect(() => {
    reset({ ...data });
    if (data?.id) setOpen.on();
    else setOpen.off();
    // eslint-disable-next-line
  }, [data]);

  const closeDialog = () => {
    if (!loading) {
      setData(null);
    }
  };

  const onSubmit = (data) => {
    let { startTime, endTime } = data;
    let sTime = dayjs("1/1/1 " + startTime).format("HH:mm:ss");
    let eTime = dayjs("1/1/1 " + endTime).format("HH:mm:ss");
    if (headoffice?.id)
      update({
        data: {
          ...data,
          startTime: sTime,
          endTime: eTime,
          headoffice: {
            id: headoffice.id,
          },
        },
      });
  };

  const removeStore = () => {
    if (data?.id) remove(data.id);
  };

  return (
    <FormDialog
      open={open}
      onClose={closeDialog}
      title="Store Details"
      disableClose
    >
      <StoreDetailsForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        loading={loading}
        open={open}
        errors={errors}
        closeDialog={closeDialog}
        action="edit"
        removeStore={removeStore}
        getValues={getValues}
        createTag={createTag}
        tagsData={tagsData}
        control={control}
        setValue={setValue}
      />
    </FormDialog>
  );
}
