import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { NOTICE_SORT_OPTIONS } from ".";
import useDebounce from "../../../hooks/useDebounce";
import useStoreDetails from "../../../hooks/useStoreDetails";
import { showErrorToast } from "../../../utils/helpers";
import { edit } from "../../../utils/mutations";
import { getNoticesByStoreId } from "../../../utils/queries";
import Filters from "../../filters";
import Spinner from "../../spinner";
import NoticeCard from "./notice-card";

function ArchiveNoticeboard() {
  const storeDetails = useStoreDetails();

  const [sortBy, setSortBy] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 250);

  const {
    data: noticeData,
    isLoading: noticeQueryLoading,
    isFetching: noticeQueryFetching,
    refetch,
  } = useQuery(
    ["/notice", { storeId: storeDetails?.id }],
    getNoticesByStoreId,
    {
      enabled: Boolean(storeDetails?.id),
    }
  );

  const { mutateAsync: updateNotice, isLoading: noticeUpdateLoading } =
    useMutation(edit, {
      onError: showErrorToast,
    });

  const notices = useMemo(() => {
    return noticeData?.data?.filter((data) => Boolean(data.archived)) || [];
  }, [noticeData]);

  const loading =
    noticeQueryLoading || noticeQueryFetching || noticeUpdateLoading;

  const unarchiveNotice = (data) => async () => {
    await updateNotice({
      method: "PUT",
      url: `/notices/${data.id}`,
      data: {
        ...data,
        archived: false,
        end: dayjs().add(1, "month").utc().format(),
        store: {
          id: storeDetails.id,
        },
      },
    });
    await refetch();
    toast.success("Removed from archives");
  };

  return (
    <>
      <h2 className="title mb-2">Archived Notices</h2>
      <Filters
        search={search}
        setSearch={setSearch}
        loading={loading}
        setSortBy={setSortBy}
        sortBy={sortBy}
        sortOptions={NOTICE_SORT_OPTIONS}
      />
      <div className="mt-2 flex flex-wrap">
        {loading ? (
          <Spinner className="mx-auto mt-32 h-10 w-10" />
        ) : (
          notices
            .filter((notice) =>
              notice.title.toLowerCase().includes(debouncedSearch.toLowerCase())
            )
            .sort((a, b) => {
              let [_sortBy, type] = sortBy?.split("-") || [];
              let _a = a[_sortBy],
                _b = b[_sortBy];
              let sort = 0;
              if (["createdAt", "end"].includes(_sortBy))
                sort = dayjs(_a).isAfter(dayjs(_b)) ? 1 : -1;
              else sort = _a > _b ? 1 : -1;
              return type === "desc" ? -sort : sort;
            })
            .map((data) => (
              <NoticeCard
                {...data}
                key={data.id}
                unarchive={unarchiveNotice(data)}
              />
            ))
        )}
      </div>
    </>
  );
}

export default ArchiveNoticeboard;
