import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useBoolean from "../../../hooks/useBoolean";
import useDebounce from "../../../hooks/useDebounce";
import useStoreDetails from "../../../hooks/useStoreDetails";
import { useAuth } from "../../../utils/auth";
import { showErrorToast } from "../../../utils/helpers";
import { edit } from "../../../utils/mutations";
import { getNoticesByStoreId } from "../../../utils/queries";
import AddNewCard from "../../add-new-card";
import Filters from "../../filters";
import Spinner from "../../spinner";
import NoticeCard from "./notice-card";
import NoticeDetailsDialog from "./notice-details-dialog";
import NoticeDialog from "./notice-dialog";

export const NOTICE_SORT_OPTIONS = [
  {
    id: "title-asc",
    label: "Title (ascending)",
  },
  {
    id: "title-desc",
    label: "Title (descending)",
  },
  {
    id: "createdAt-desc",
    label: "Date Created (newest)",
  },
  {
    id: "createdAt-asc",
    label: "Date Created (oldest)",
  },
  {
    id: "end-desc",
    label: "Expiration Date (earliest)",
  },
  {
    id: "end-asc",
    label: "Expiration Date (latest)",
  },
];

function Noticeboard() {
  const { user } = useAuth();
  const storeDetails = useStoreDetails();

  const [sortBy, setSortBy] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 250);

  const {
    data: noticeData,
    isLoading: noticeQueryLoading,
    isFetching: noticeQueryFetching,
    refetch,
  } = useQuery(
    ["/notice", { storeId: storeDetails?.id }],
    getNoticesByStoreId,
    {
      enabled: Boolean(storeDetails?.id),
    }
  );

  const { mutateAsync: updateNotice, isLoading: noticeUpdateLoading } =
    useMutation(edit, {
      onError: showErrorToast,
    });

  const loading =
    noticeQueryLoading || noticeQueryFetching || noticeUpdateLoading;

  const [open, setOpen] = useBoolean();
  const [noticeDetails, setNoticeDetails] = useState(null);

  const [editNoticeDetails, setEditNoticeDetails] = useState(null);

  const notices = useMemo(() => {
    return noticeData?.data?.filter((data) => !Boolean(data.archived)) || [];
  }, [noticeData]);

  useEffect(() => {
    if (!open) setEditNoticeDetails(null);
  }, [open]);

  const onClose = () => setOpen.off();

  const archiveNotice = (data) => async () => {
    await updateNotice({
      method: "PUT",
      url: `/notices/${data.id}`,
      data: {
        ...data,
        end: dayjs().utc().format(),
        archived: true,
        store: {
          id: storeDetails.id,
        },
        // Just because there are some notices don't have assignedTo field which was added later, initially notices were only for teams
        assignedTo: data.assignedTo || "teams",
      },
    });
    await refetch();
    toast.success("Archived notice");
  };

  const readMore = (data) => () => {
    setNoticeDetails(data);
  };

  const editNotice = (data) => () => {
    setEditNoticeDetails(data);
    setOpen.on();
  };

  const readNotice = async ({ pin }) => {
    if (!pin || pin.length !== 4)
      return toast.error("Please enter a valid PIN");

    await updateNotice({
      method: "PUT",
      url: `/custom/read-notice/${noticeDetails.id}`,
      data: {
        store: {
          id: storeDetails.id,
        },
        notice: noticeDetails,
        pin,
      },
    });
    await refetch();
    setNoticeDetails(null);
    toast.success("Marked as read");
  };

  return (
    <>
      <h2 className="title mb-2">Notices</h2>
      <Filters
        search={search}
        setSearch={setSearch}
        loading={loading}
        setSortBy={setSortBy}
        sortBy={sortBy}
        sortOptions={NOTICE_SORT_OPTIONS}
      />
      <NoticeDialog
        open={open}
        onClose={loading ? null : onClose}
        loading={loading}
        data={editNoticeDetails}
      />
      <NoticeDetailsDialog
        {...noticeDetails}
        loading={loading}
        setNoticeDetails={setNoticeDetails}
        readNotice={readNotice}
      />
      <div className="mt-2 flex flex-wrap">
        {loading ? (
          <Spinner className="mx-auto mt-32 h-10 w-10" />
        ) : (
          <>
            {notices
              .filter((notice) =>
                notice.title
                  .toLowerCase()
                  .includes(debouncedSearch.toLowerCase())
              )
              .sort((a, b) => {
                let [_sortBy, type] = sortBy?.split("-") || [];
                let _a = a[_sortBy],
                  _b = b[_sortBy];
                let sort = 0;
                if (["createdAt", "end"].includes(_sortBy))
                  sort = dayjs(_a).isAfter(dayjs(_b)) ? 1 : -1;
                else sort = _a > _b ? 1 : -1;
                return type === "desc" ? -sort : sort;
              })
              .map((data) => (
                <NoticeCard
                  {...data}
                  key={data.id}
                  archive={archiveNotice(data)}
                  read={readMore(data)}
                  edit={user ? editNotice(data) : null}
                />
              ))}
            <AddNewCard onClick={setOpen.on} disabled={loading} />
          </>
        )}
      </div>
    </>
  );
}

export default Noticeboard;
