import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import useBoolean from "../../../hooks/useBoolean";
import { useAuth } from "../../../utils/auth";
import { getAdminHeadoffice } from "../../../utils/queries";
import Spinner from "../../spinner";
import NoticeDetailsDialog from "../../store/noticeboard/notice-details-dialog";
import NoticeDialog from "../../store/noticeboard/notice-dialog";
import { ADMIN_ASSIGNED_OPTIONS } from "../../store/tasks/constants";
import Table from "../../table";

function Notice() {
  const { data } = useAuth();
  const headoffice = data?.manager?.headoffice;

  const [noticeDetails, setNoticeDetails] = useState(null);

  const [open, setOpen] = useBoolean();

  const {
    data: headofficeData,
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery,
  } = useQuery(
    ["/admin/headoffice", { headofficeId: headoffice?.id }],
    getAdminHeadoffice,
    { enabled: Boolean(headoffice?.id) }
  );

  const loading = isLoadingQuery || isFetchingQuery;

  const viewDetails = (data) => {
    setNoticeDetails(data);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Title",
        accessor: "title",
        width: "15%",
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ row }) => {
          let value = row.values["description"];
          return (
            <div
              dangerouslySetInnerHTML={{ __html: value }}
              className="multiline-ellipsis"
            />
          );
        },
        width: "30%",
      },
      {
        Header: "Assigned To",
        accessor: "assignedTo",
        Cell: ({ row }) => {
          let value = row.values["assignedTo"];
          let label = Object.values(ADMIN_ASSIGNED_OPTIONS).find(
            (option) => value === option.value
          )?.text;
          return <span>{value && label ? label : "-"}</span>;
        },
        width: "10%",
      },
      {
        Header: "Assignees",
        accessor: "assignees",
        Cell: ({ row }) => {
          let assignedTo = row.original["assignedTo"];
          let storeName = row.original["store"]?.name;
          let stores = row.original["stores"];

          let isAssignedToStores =
            assignedTo === ADMIN_ASSIGNED_OPTIONS.STORES.value;

          if (isAssignedToStores) {
            return <span>{stores.map(({ name }) => name).join(", ")}</span>;
          }
          return <span>{storeName}</span>;
        },
        width: "15%",
      },
      {
        Header: "Expiration Time",
        accessor: "end",
        Cell: ({ row }) => {
          let value = row.values["end"];
          return (
            <span>
              {value ? dayjs(value).format("DD MMM, YYYY hh:mm A") : "-"}
            </span>
          );
        },
        width: "15%",
      },
      {
        Header: "",
        accessor: "customButton",
        Cell: ({ row }) => {
          return (
            <button
              className="btn-primary"
              onClick={() => viewDetails(row.original)}
            >
              View Details
            </button>
          );
        },
        width: "15%",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    let notices =
      headofficeData?.notices.map((notice) => ({
        ...notice,
        store: notice.createdForStore,
      })) || [];
    return notices;
  }, [headofficeData]);

  const onClose = () => setOpen.off();

  return (
    <>
      <NoticeDialog
        open={open}
        onClose={loading ? null : onClose}
        loading={loading}
        adminPanelDetails={{
          forAdminPanel: true,
          storesData: headofficeData?.stores,
        }}
      />
      <NoticeDetailsDialog
        {...noticeDetails}
        loading={loading}
        setNoticeDetails={setNoticeDetails}
        forAdminPanel
      />
      {loading ? (
        <div className="fixed top-0 left-0 z-10 h-full w-full bg-white/40">
          <Spinner className="m-auto mt-[45vh] h-10 w-10" />
        </div>
      ) : null}
      <div className="mb-6 flex items-center justify-between">
        <h2 className="title">Notices</h2>
        <button className="btn-primary" onClick={setOpen.on}>
          Create Notice
        </button>
      </div>
      <Table columns={columns} data={rows} />
    </>
  );
}

export default Notice;
