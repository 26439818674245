import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/auth";
import { USER_ROLES } from "../../utils/constants";

export default function StoreCard({ name, address, id, setSelectedStore }) {
  const { role } = useAuth();
  const navigate = useNavigate();
  const noOfHosts = 1;
  return (
    <div className="py-4 pr-8">
      <div className="mx-auto w-96 overflow-hidden rounded-md shadow-lg">
        <div className="bg-white p-6">
          <h3 className="text-center text-base font-bold">{name}</h3>
          <h3 className="mt-3 mb-20 text-center text-sm text-cool-gray">
            {address}
          </h3>
          <button
            className="button-default mx-auto block w-36"
            onClick={() => navigate(`/store/${id}`)}
          >
            View Details
          </button>
          {[
            USER_ROLES.HEADOFFICE_ADMIN,
            USER_ROLES.HEADOFFICE_MANAGER,
          ].includes(role) && (
            <button
              className="button-default mx-auto mt-4 block w-36"
              onClick={() => setSelectedStore(id)}
            >
              Settings
            </button>
          )}
        </div>
        <div className="bg-light-gray px-6 py-3">
          <span className="mr-1 text-sm font-bold text-dark-blue">
            {noOfHosts}
          </span>
          <span className="text-xs text-cool-gray">{`host${
            noOfHosts > 1 ? "s" : ""
          }`}</span>
        </div>
      </div>
    </div>
  );
}
