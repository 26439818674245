export const API_URL = process.env.REACT_APP_API_URL;

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const USER_ROLES = {
  HEADOFFICE_ADMIN: "headoffice_admin",
  HEADOFFICE_MANAGER: "headoffice_manager",
  STORE_MANAGER: "store_manager",
  STORE_MEMBER: "store_member",
};

export const ROLES = {
  [USER_ROLES.HEADOFFICE_ADMIN]: "Headoffice Admin",
  [USER_ROLES.HEADOFFICE_MANAGER]: "Headoffice Manager",
  [USER_ROLES.STORE_MANAGER]: "Store Manager",
  [USER_ROLES.STORE_MEMBER]: "Store Member",
};
