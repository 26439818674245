import { SearchIcon } from "@heroicons/react/outline";
import React from "react";
import InputGroup from "./input-group";

function SearchInput({ disabled, search, setSearch }) {
  return (
    <div className="relative">
      <InputGroup
        title="Search"
        htmlFor="search"
        id="search"
        placeholder="Enter your search"
        disabled={disabled}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className="w-[300px]"
        inputStyles="py-2 pr-12"
      />
      <SearchIcon className="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 text-primary" />
    </div>
  );
}

export default SearchInput;
