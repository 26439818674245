import {
  HtmlEditor,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useStoreDetails from "../../../hooks/useStoreDetails";
import { useAuth } from "../../../utils/auth";
import { showErrorToast } from "../../../utils/helpers";
import { edit } from "../../../utils/mutations";
import { getTeamsByStoreId } from "../../../utils/queries";
import FormDialog from "../../dialogs/form-dialog";
import InputGroup from "../../input-group";
import Selector from "../../selector";
import { ADMIN_ASSIGNED_OPTIONS } from "../tasks/constants";
import "./style.css";

function NoticeDialog({
  onClose: close,
  open,
  loading: isLoading,
  adminPanelDetails = {},
  data = null,
}) {
  var editor = {};

  const { forAdminPanel, storesData } = adminPanelDetails;
  const initialAssignedTo = forAdminPanel ? "teams" : "all";

  const { user, data: authData } = useAuth();
  const headofficeId = authData?.manager?.headoffice?.id;

  const queryClient = useQueryClient();
  const { mutateAsync: updateNotice, isLoading: noticeUpdateLoading } =
    useMutation(edit, {
      onError: showErrorToast,
    });

  const storeDetails = useStoreDetails();

  const { data: teamsQueryData, isLoading: queryLoading } = useQuery(
    ["/teams", { storeId: storeDetails?.id }],
    getTeamsByStoreId,
    {
      enabled: Boolean(storeDetails?.id),
    }
  );

  const loading = queryLoading || noticeUpdateLoading || isLoading;

  const _end = dayjs().add(1, "month").format().substring(0, 16);

  const [pin, setPin] = useState("");
  const [description, setDescription] = useState(null);
  const [teams, setTeams] = useState([]);
  const [title, setTitle] = useState("");
  const [endTime, setEndTime] = useState(_end);
  const [assignedTo, setAssignedTo] = useState(initialAssignedTo);

  // For admin panel only
  const [stores, setStores] = useState("");
  const multiStoreSelctionEnabled =
    assignedTo === ADMIN_ASSIGNED_OPTIONS.STORES.value;
  const storeSelectorLabel = multiStoreSelctionEnabled ? "Stores" : "Store";
  const assignedToOptions = [
    forAdminPanel ? null : ADMIN_ASSIGNED_OPTIONS.ALL,
    ADMIN_ASSIGNED_OPTIONS.TEAMS,
    ADMIN_ASSIGNED_OPTIONS.STORES,
  ].filter(Boolean);
  const teamsOptions = forAdminPanel
    ? storesData.find(({ id }) => id === stores)?.teams
    : teamsQueryData?.data;

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setDescription(data.description);
      setEndTime(dayjs(data.end).format().substring(0, 16));
      setTeams(data.teams?.map(({ id }) => id) || []);
      // Just because there are some notices don't have assignedTo field which was added later, initially notices were only for teams
      setAssignedTo(data.assignedTo || "teams");
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setTeams([]);
    setStores("");
    // eslint-disable-next-line
  }, [assignedTo]);

  useEffect(() => {
    if (assignedTo === "teams") setTeams([]);
    // eslint-disable-next-line
  }, [stores]);

  const onClose = () => {
    if (close) {
      setDescription(null);
      setTeams([]);
      setTitle("");
      setEndTime(_end);
      setAssignedTo(initialAssignedTo);
      setPin("");
      setStores("");
      close();
    }
  };

  const editNotice = async () => {
    let url = data ? `/notices/${data.id}` : "/notices";
    let method = data ? "PUT" : "POST";
    let end = dayjs(endTime).utc().format();
    let _title = title.trim();
    if (
      !description ||
      !_title ||
      !endTime ||
      !assignedTo ||
      (assignedTo === "teams" && !teams.length) ||
      (forAdminPanel && assignedTo === "stores" && !stores.length) ||
      (!user && !pin)
    )
      return toast.error("Please input all fields");

    if (!user && pin.length !== 4)
      return toast.error("Please input a valid pin");

    let _store =
      forAdminPanel && assignedTo === "teams"
        ? { store: { id: stores } }
        : { store: { id: storeDetails?.id } };
    let __stores = forAdminPanel && assignedTo === "stores" ? { stores } : {};

    await updateNotice({
      method,
      url,
      data: {
        title: _title,
        description,
        end,
        teams,
        assignedTo,
        ..._store,
        ...__stores,
        readBies: [],
        addedBy: {
          pin,
        },
        createdByAdmin: forAdminPanel,
        headoffice: {
          id: headofficeId,
        },
      },
    });
    if (forAdminPanel)
      await queryClient.refetchQueries(["/admin/headoffice", { headofficeId }]);
    else
      await queryClient.refetchQueries([
        "/notice",
        { storeId: storeDetails?.id },
      ]);
    onClose();
    toast.success(data ? "Updated notice successfully" : "Created new notice");
  };

  return (
    <FormDialog
      title={
        <>
          <h3 className="text-xl">Add Notice</h3>
          <InputGroup
            type="datetime-local"
            title="Expiry Time"
            htmlFor="endTime"
            id="endTime"
            disabled={loading}
            placeholder="Enter notice end time"
            onChange={(e) => setEndTime(e.target.value)}
            value={endTime}
            className="ml-auto w-[250px] pb-0"
          />
        </>
      }
      onClose={onClose}
      open={open}
      disableClose
      modalStyles="w-[calc(800px + 3rem)]"
    >
      <InputGroup
        title="Title"
        htmlFor="title"
        id="title"
        placeholder="Write notice title here..."
        disabled={loading}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div className="w-full py-4">
        <div className="overflow-hidden rounded-md border border-black/30">
          <RichTextEditorComponent
            value={description}
            id="notice-editor-description"
            ref={(richtexteditor) => (editor = richtexteditor)}
            width={800}
            height={200}
            placeholder="Write notice content here..."
            toolbarSettings={{
              items: [
                "Bold",
                "Italic",
                "Underline",
                "StrikeThrough",
                // "FontName",
                "FontSize",
                // "FontColor",
                // "BackgroundColor",
                // "LowerCase",
                // "UpperCase",
                "|",
                "Formats",
                "Alignments",
                "OrderedList",
                "UnorderedList",
                "Outdent",
                "Indent",
                "|",
                "CreateLink",
                // "Image",
                // "|",
                // "ClearFormat",
                // "Print",
                // "SourceCode",
                // "FullScreen",
                "|",
                "Undo",
                "Redo",
              ],
              type: "Expand",
              enableFloating: true,
            }}
            change={({ value }) => setDescription(value)}
            readonly={loading}
          >
            <Inject services={[HtmlEditor, Toolbar, Link, QuickToolbar]} />
          </RichTextEditorComponent>
        </div>
      </div>
      <div className="flex py-4">
        <Selector
          value={assignedTo}
          onChange={setAssignedTo}
          data={assignedToOptions.map((item) => ({
            ...item,
            id: item.value,
            disabled: item.value === "stores" && !forAdminPanel,
          }))}
          placeholder="Select assignees"
          loading={loading}
          nameKey="text"
          label="Assigned To"
          multiple={false}
        />
      </div>
      {forAdminPanel &&
        [
          ADMIN_ASSIGNED_OPTIONS.STORES.value,
          ADMIN_ASSIGNED_OPTIONS.TEAMS.value,
        ].includes(assignedTo) && (
          <div className="flex py-4">
            <Selector
              value={stores || []}
              onChange={setStores}
              data={storesData || []}
              placeholder={`Select ${storeSelectorLabel.toLocaleLowerCase()}`}
              loading={loading}
              nameKey="name"
              label={storeSelectorLabel}
              multiple={multiStoreSelctionEnabled}
            />
          </div>
        )}
      {assignedTo === ADMIN_ASSIGNED_OPTIONS.TEAMS.value && (
        <div className="flex py-4">
          <Selector
            value={teams}
            onChange={setTeams}
            data={teamsOptions || []}
            placeholder="Select teams"
            loading={loading}
            nameKey="name"
            label="Teams"
          />
        </div>
      )}
      <div className="mx-auto flex w-2/3 items-center justify-between pt-6">
        <button
          className="btn-secondary mx-2 w-32"
          disabled={loading}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </button>
        {!data && !user && (
          <InputGroup
            title="PIN"
            htmlFor="pin"
            id="pin"
            placeholder="Enter PIN"
            disabled={loading}
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            type="password"
            className="w-[150px]"
          />
        )}
        <button
          className="btn-primary ml-2 w-32"
          disabled={loading}
          onClick={editNotice}
        >
          {data ? "Update" : "Publish"}
        </button>
      </div>
    </FormDialog>
  );
}

export default NoticeDialog;
