import React from "react";
import SearchInput from "./search-input";
import Selector from "./selector";

function Filters({
  search,
  setSearch,
  sortBy,
  setSortBy,
  sortOptions,
  loading,
}) {
  return (
    <div className="flex items-center">
      <SearchInput search={search} setSearch={setSearch} disabled={loading} />
      <div className="ml-auto w-[300px]">
        <Selector
          label="Sort By"
          placeholder="Select a property"
          value={sortBy}
          onChange={setSortBy}
          selectedOptionStyle="!py-0"
          data={sortOptions}
          nameKey="label"
          loading={loading}
          multiple={false}
        />
      </div>
    </div>
  );
}

export default Filters;
