import { CalendarIcon } from "@heroicons/react/outline";
import { useAuth } from "../../../utils/auth";
import { USER_ROLES } from "../../../utils/constants";
import dayjs from "dayjs";
import CustomPopver from "../../custom-popover";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

function NoticeCard({ archive, unarchive, read, edit, ...data }) {
  const {
    description,
    title,
    createdAt,
    end,
    teams,
    readBies,
    stores,
    createdByAdmin,
  } = data;

  const { role } = useAuth();
  const notStaffMember = [
    USER_ROLES.HEADOFFICE_ADMIN,
    USER_ROLES.HEADOFFICE_MANAGER,
    USER_ROLES.STORE_MANAGER,
  ].includes(role);

  return (
    <div className="py-4 pr-8">
      <div className="mx-auto h-full w-96 rounded-md shadow-lg">
        <div className="ellipsis flex items-center border-b-[1px] border-black/20 px-6 py-3 text-xs text-black/40">
          <CalendarIcon className="mr-2 h-5 w-5" />
          <span className="mt-[0.125rem]">
            {dayjs(createdAt).format("DD/MM/YYYY")} - Posted{" "}
            {dayjs(createdAt).fromNow()} - {unarchive ? "Expired" : "Expires"}{" "}
            {dayjs(end).fromNow()}
          </span>
        </div>
        <div className="border-b-[1px] border-black/20 p-6 py-3">
          <h4 className="ellipsis mb-2 max-w-full font-semibold" title={title}>
            {title}
          </h4>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="notice-card-description custom-scrollbar h-[100px] max-w-full overflow-y-hidden text-justify hover:overflow-y-auto"
          />
          <div className="hide-scrollbar flex items-center overflow-x-auto pt-3">
            {teams?.length ? (
              teams?.map((team) => (
                <span className="mr-3 mt-3 whitespace-nowrap rounded-full bg-black/10 py-[0.375rem] px-4 text-xs font-semibold">
                  {team.name}
                </span>
              ))
            ) : stores?.length ? (
              stores?.map((store) => (
                <span className="mr-3 mt-3 whitespace-nowrap rounded-full bg-black/10 py-[0.375rem] px-4 text-xs font-semibold">
                  {store.name}
                </span>
              ))
            ) : (
              <span className="mr-3 mt-3 rounded-full bg-black/10 py-[0.375rem] px-4 text-xs font-semibold">
                All
              </span>
            )}
          </div>
        </div>
        <div>
          {readBies?.length ? (
            <div className="flex flex-wrap items-center px-6 pb-1 pt-3">
              {readBies.map(({ name, type, id, color, readAt }) => (
                <Tooltip
                  key={id + type}
                  overlay={
                    <p className="text-xs">
                      Read by <strong>{name}</strong> on{" "}
                      <strong>
                        {dayjs(readAt).format("DD MMMM, YYYY hh:mm A")}
                      </strong>
                    </p>
                  }
                  overlayClassName="w-fit"
                  overlayInnerStyle={{ background: "#fff", opacity: 1 }}
                >
                  <span
                    className="my-2 mr-3 cursor-pointer rounded-full py-[0.375rem] px-4 text-xs font-semibold text-white"
                    style={{ background: color || "#000" }}
                  >
                    {name}
                  </span>
                </Tooltip>
              ))}
            </div>
          ) : null}

          <div
            className={`flex items-center justify-end px-6 pb-3 ${
              readBies?.length ? "pt-1" : "pt-16"
            }`}
          >
            {read && (
              <button className="btn-primary w-24" onClick={read}>
                Read
              </button>
            )}
            {edit && (
              <button
                className="btn-secondary ml-3 w-24"
                onClick={edit}
                disabled={createdByAdmin}
              >
                Edit
              </button>
            )}
            {notStaffMember && archive && (
              <CustomPopver
                panelStyles="absolute bottom-10 -left-1/2 -translate-x-[15%] z-10 w-72 rounded-md bg-white shadow-md"
                buttonLabel="Delete"
                buttonStyles="btn-danger w-24 !min-w-[auto] ml-3 !mr-0"
                onOk={(close) => {
                  close();
                  archive();
                }}
                onCancel={(close) => close()}
                message="Are you sure you want to archive it?"
                disabled={createdByAdmin}
              />
            )}
            {unarchive && (
              <button
                className="btn-primary ml-3 w-24"
                onClick={unarchive}
                disabled={createdByAdmin}
              >
                Unarchive
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeCard;
