import { Tab } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import Notice from "../components/overview/notice";
import Store from "../components/overview/store";
import useCheckAuthRoles from "../hooks/useCheckAuthRoles";
import { USER_ROLES } from "../utils/constants";
import { classNames } from "../utils/helpers";

const TABS = [
  {
    label: "Stores",
    key: "stores",
    component: <Store />,
  },
  // {
  //   label: "Tasks",
  //   key: "tasks",
  //   component: <h1>Tasks</h1>,
  // },
  {
    label: "Notices",
    key: "notices",
    component: <Notice />,
  },
];

function HeadofficeOverview() {
  useCheckAuthRoles({
    roles: [USER_ROLES.HEADOFFICE_MANAGER, USER_ROLES.HEADOFFICE_ADMIN],
  });

  return (
    <Tab.Group>
      <Tab.List className="bg-white px-6 pt-2 pb-4 shadow-md">
        {TABS.map(({ label, key, disabled }) => (
          <Tab as={Fragment} key={key} disabled={disabled}>
            {({ selected }) => (
              <button
                className={classNames(
                  "mr-4 min-w-[8rem] rounded-md py-3 px-6 text-xs font-semibold uppercase shadow-md transition-all duration-300 ease-in-out hover:bg-cool-orange hover:text-white",
                  selected ? "bg-cool-orange text-white" : "bg-white",
                  disabled && "cursor-not-allowed "
                )}
                disabled={disabled}
              >
                {label}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="p-6">
        {TABS.map(({ component }) => (
          <Tab.Panel>{component}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default HeadofficeOverview;
