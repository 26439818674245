import { strapiAPI } from "./api";

export const login = async () => {
  const response = await strapiAPI.get("/custom/login");
  return response.data;
};

export const getStoresByHeadofficeId = async ({ queryKey }) => {
  const [, { headofficeId }] = queryKey;
  const response = await strapiAPI.get(`/custom/${headofficeId}/stores`);
  return response.data;
};

export const getStaffByStoreId = async ({ queryKey }) => {
  const [, { storeId }] = queryKey;
  const response = await strapiAPI.get(`/custom/get-staff/${storeId}`);
  return response.data;
};

export const getTeamsByStoreId = async ({ queryKey }) => {
  const [, { storeId }] = queryKey;
  const response = await strapiAPI.get(`/custom/get-teams/${storeId}`);
  return response.data;
};

export const getNoticesByStoreId = async ({ queryKey }) => {
  const [, { storeId }] = queryKey;
  const response = await strapiAPI.get(`/custom/get-notices/${storeId}`);
  return response.data;
};

export const getTasksByStoreId = async ({ queryKey }) => {
  const [, { storeId }] = queryKey;
  const response = await strapiAPI.get(`/custom/get-tasks/${storeId}`);
  return response.data;
};

export const getTagsByHeadofficeId = async ({ queryKey }) => {
  const [, { headofficeId }] = queryKey;
  const response = await strapiAPI.get(`/custom/get-tags/${headofficeId}`);
  return response.data;
};

export const getHeadoffice = async ({ queryKey }) => {
  const [, { headofficeId }] = queryKey;
  const response = await strapiAPI.get(`/headoffices/${headofficeId}`);
  return response.data;
};

// ADMIN ROUTES
export const getAdminHeadoffice = async ({ queryKey }) => {
  const [, { headofficeId }] = queryKey;
  const response = await strapiAPI.get(
    `/custom/admin/headoffice/${headofficeId}`
  );
  return response.data;
};
