import * as React from "react";
import { textClassName, textColor } from "./helpers";

export const ThemeContext = React.createContext({
  color: null,
  setColor: () => {},
  textClassName: null,
  textColor: null,
  bgColor: null,
});

export const useTheme = () => {
  return React.useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const [color, setColor] = React.useState(null);

  const setColorCallback = React.useCallback(c => setColor(c), []);

  return (
    <ThemeContext.Provider
      value={{
        color,
        setColor: setColorCallback,
        textClassName: textClassName(color),
        textColor: textColor(color),
        bgColor: color,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
