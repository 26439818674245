import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import Staff from "../components/store-settings/staff";
import Teams from "../components/store-settings/teams";
import useCheckAuthRoles from "../hooks/useCheckAuthRoles";
import Container from "../layouts/container";
import { USER_ROLES } from "../utils/constants";
import { classNames } from "../utils/helpers";

const TABS = [
  {
    label: "Staff Management",
    key: "staff",
  },
  {
    label: "Team Management",
    key: "teams",
  },
];

function StoreSettings() {
  useCheckAuthRoles({
    roles: [
      USER_ROLES.HEADOFFICE_MANAGER,
      USER_ROLES.HEADOFFICE_ADMIN,
      USER_ROLES.STORE_MANAGER,
    ],
    checkStoreAdmin: true,
  });
  return (
    <Container>
      <Tab.Group>
        <Tab.List className="bg-white px-6 pt-2 pb-4 shadow-md">
          {TABS.map(({ label, key, disabled }) => (
            <Tab as={Fragment} key={key} disabled={disabled}>
              {({ selected }) => (
                <button
                  className={classNames(
                    "mr-4 min-w-[8rem] rounded-md py-3 px-6 text-xs font-semibold uppercase shadow-md transition-all duration-300 ease-in-out hover:bg-cool-orange hover:text-white",
                    selected ? "bg-cool-orange text-white" : "bg-white",
                    disabled && "cursor-not-allowed "
                  )}
                  disabled={disabled}
                >
                  {label}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="p-6">
          <Tab.Panel>
            <Staff />
          </Tab.Panel>
          <Tab.Panel>
            <Teams />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Container>
  );
}

export default StoreSettings;
