import { CheckIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useBoolean from "../../hooks/useBoolean";
import useStoreDetails from "../../hooks/useStoreDetails";
import { useAuth } from "../../utils/auth";
import { ROLES, USER_ROLES } from "../../utils/constants";
import { classNames, showErrorToast } from "../../utils/helpers";
import { createManager, edit } from "../../utils/mutations";
import { getStaffByStoreId } from "../../utils/queries";
import AddNewCard from "../add-new-card";
import CustomPopver from "../custom-popover";
import FormDialog from "../dialogs/form-dialog";
import InputGroup from "../input-group";
import Selector from "../selector";
import Spinner from "../spinner";
import { USER_COLOR_OPTIONS } from "../store/tasks/constants";
import CustomMenu from "./custom-menu";

function ColorPallete({ selectedColor, setSelectedColor }) {
  const colors =
    !selectedColor || USER_COLOR_OPTIONS.includes(selectedColor)
      ? USER_COLOR_OPTIONS
      : [...USER_COLOR_OPTIONS, selectedColor];
  return (
    <div className="flex flex-wrap items-center justify-center">
      {colors.map((color) => {
        return (
          <div className="p-2 py-5" key={color}>
            <span
              className="relative cursor-pointer rounded-md px-10 py-[0.375rem] shadow-md"
              style={{ background: color }}
              onClick={() => setSelectedColor(color)}
            >
              {color === selectedColor && (
                <CheckIcon className=" absolute top-1/2 left-1/2 h-7 w-7 -translate-y-1/2 -translate-x-1/2 text-white" />
              )}
            </span>
          </div>
        );
      })}
    </div>
  );
}

function StaffDetailsDialog({
  open,
  closeDialog,
  action = "add",
  onSubmit,
  loading = false,
  data,
  handleDelete = () => {},
}) {
  const editMode = action === "edit";

  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState("");

  const [color, setColor] = useState("");
  const [colorError, setColorError] = useState("");

  const disableRole = loading || editMode;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      pin: "",
      manager: {
        email: "",
      },
    },
  });

  useEffect(() => {
    // Setting data for edit
    if (data) {
      reset({ ...data });
      setColor(data.color);
      setRole("member"); // TODO: implement edit for manager as well
    } else resetForm();
    // eslint-disable-next-line
  }, [data, open]);

  const resetForm = async () => {
    reset({
      name: "",
      pin: "",
      manager: {
        email: "",
      },
    });
    setRole("");
    setRoleError("");
    setColor("");
    setColorError("");
  };

  const submit = (data) => {
    if (!role) return setRoleError("Role is required");
    if (!color) return setColorError("Color is required");
    onSubmit({ data: { ...data, role, color }, reset: resetForm });
  };

  const deleteEntity = () => {
    if (editMode)
      handleDelete({
        data: {
          ...data,
          role,
        },
        reset: resetForm,
      });
  };

  return (
    <FormDialog
      open={open}
      onClose={closeDialog}
      title={editMode ? "Update Staff" : "Add Staff"}
      disableClose
    >
      <form onSubmit={handleSubmit(submit)}>
        <InputGroup
          title="Name"
          autoFocus
          htmlFor="name"
          id="name"
          error={open && errors.name}
          placeholder="Enter name"
          disabled={loading}
          register={() =>
            register("name", {
              required: {
                value: true,
                message: "Name is required",
              },
            })
          }
        />
        <InputGroup
          title="Pin"
          htmlFor="pin"
          id="pin"
          error={open && errors.pin}
          placeholder="Enter pin"
          disabled={loading}
          type="password"
          register={() =>
            register("pin", {
              required: {
                value: true,
                message: "Pin is required",
              },
              minLength: {
                value: 4,
                message: "Pin must be at least 4 numbers",
              },
              maxLength: {
                value: 4,
                message: "Pin must be at most 4 numbers",
              },
              pattern: {
                value: /^[0-9]*$/,
                message: "Pin must be a number",
              },
            })
          }
        />
        <div className="py-4">
          <Selector
            label="Role"
            placeholder="Select a role"
            value={role}
            onChange={(_role) => {
              setRole(_role);
              setRoleError("");
            }}
            selectedOptionStyle="!py-[0.25rem]"
            data={[
              {
                label: "Member",
                id: "member",
              },
              {
                label: "Manager",
                id: "manager",
              },
            ]}
            nameKey="label"
            loading={loading || disableRole}
            multiple={false}
          />
          {open && roleError && (
            <small className="mt-2 ml-5 text-xs text-error">{roleError}</small>
          )}
        </div>
        {role === "manager" && (
          <InputGroup
            title="Manager Email"
            htmlFor="managerEmail"
            id="managerEmail"
            error={open && errors.manager?.email}
            placeholder="Enter manager email"
            disabled={loading}
            register={() =>
              register("manager.email", {
                required: {
                  value: true,
                  message: "Email is required",
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Enter a valid email",
                },
              })
            }
          />
        )}
        {/* <div> */}
        <ColorPallete selectedColor={color} setSelectedColor={setColor} />
        {open && colorError && (
          <small className="mt-2 block text-center text-xs text-error">
            {colorError}
          </small>
        )}
        {/* </div> */}
        {/* <InputGroup
          title="Color"
          htmlFor="color"
          id="color"
          error={open && errors.color}
          disabled={loading}
          register={() =>
            register("color", {
              required: {
                value: true,
                message: "Color is required",
              },
            })
          }
          type="color"
          inputStyles="py-0 h-[38px]"
        /> */}
        <div className="mx-auto flex w-[80%] items-center justify-between pt-6">
          {editMode ? (
            <CustomPopver
              panelStyles="absolute bottom-10 -left-1/2 z-10 w-72 rounded-md bg-white shadow-md"
              buttonLabel="Delete"
              buttonStyles="btn-danger !mr-0"
              loading={loading}
              onOk={(close) => {
                close();
                deleteEntity();
              }}
              onCancel={(close) => close()}
            />
          ) : null}
          <button
            className="btn-secondary w-32"
            disabled={loading}
            onClick={(e) => {
              closeDialog();
              e.preventDefault();
            }}
          >
            Cancel
          </button>
          <button className="btn-primary w-32" disabled={loading} type="submit">
            {action === "edit" ? "Update" : "Add"}
          </button>
        </div>
        {/* <div className="flex">
          <InputGroup
            title="Name"
            autoFocus
            htmlFor="name"
            id="name"
            error={open && errors.name}
            placeholder="Enter name"
            disabled={loading}
            register={() =>
              register("name", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              })
            }
          />
          <span className="w-20" />
          <RadioGroup
            {...register("role", {
              required: {
                value: true,
                message: "Role is required",
              },
            })}
            onChange={setRole}
            className="w-full py-2"
            value={role}
          >
            <RadioGroup.Label className="mb-4 block text-sm">
              Role
            </RadioGroup.Label>
            <div className="flex items-center">
              <RadioGroup.Option value="member" disabled={disableRole}>
                {({ checked }) => (
                  <span
                    className={`mr-2 mt-2 rounded-md py-2 px-4 text-xs font-semibold shadow-sm ${
                      checked ? "bg-primary text-white" : "bg-gray-200"
                    } ${disableRole ? "cursor-not-allowed" : "cursor-pointer"}`}
                  >
                    Member
                  </span>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="manager" disabled={disableRole}>
                {({ checked }) => (
                  <span
                    className={`ml-2 rounded-md py-2 px-4 text-xs font-semibold shadow-sm ${
                      checked ? "bg-primary text-white" : "bg-gray-200"
                    } ${disableRole ? "cursor-not-allowed" : "cursor-pointer"}`}
                  >
                    Manager
                  </span>
                )}
              </RadioGroup.Option>
            </div>
            {open && errors.role && (
              <small className="mt-2 text-xs text-error">
                {errors.role.message}
              </small>
            )}
          </RadioGroup>
        </div>
        {role === "manager" && (
          <div className="flex">
            <InputGroup
              title="Manager Email"
              htmlFor="managerEmail"
              id="managerEmail"
              error={open && errors.manager?.email}
              placeholder="Enter manager email"
              disabled={loading}
              register={() =>
                register("manager.email", {
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Enter a valid email",
                  },
                })
              }
            />
          </div>
        )}
        <div className="flex">
          <InputGroup
            title="Pin"
            htmlFor="pin"
            id="pin"
            error={open && errors.pin}
            placeholder="Enter pin"
            disabled={loading}
            type="password"
            register={() =>
              register("pin", {
                required: {
                  value: true,
                  message: "Pin is required",
                },
                minLength: {
                  value: 4,
                  message: "Pin must be at least 4 numbers",
                },
                maxLength: {
                  value: 4,
                  message: "Pin must be at most 4 numbers",
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Pin must be a number",
                },
              })
            }
          />
          <span className="w-20" />
          <InputGroup
            title="Color"
            htmlFor="color"
            id="color"
            error={open && errors.color}
            disabled={loading}
            register={() =>
              register("color", {
                required: {
                  value: true,
                  message: "Color is required",
                },
              })
            }
            type="color"
            inputStyles="py-0 h-[38px]"
          />
        </div>
        <div className="flex items-center justify-end pt-6">
          {editMode ? (
            <CustomPopver
              panelStyles="absolute bottom-10 -left-1/2 z-10 w-72 rounded-md bg-white shadow-md"
              buttonLabel="Delete"
              buttonStyles="btn-danger"
              loading={loading}
              onOk={(close) => {
                close();
                deleteEntity();
              }}
              onCancel={(close) => close()}
            />
          ) : null}
          <button
            className="btn-secondary mx-2 w-32"
            disabled={loading}
            onClick={(e) => {
              closeDialog();
              e.preventDefault();
            }}
          >
            Cancel
          </button>
          <button
            className="btn-primary ml-2 w-32"
            disabled={loading}
            type="submit"
          >
            {action === "edit" ? "Update" : "Add"}
          </button>
        </div> */}
      </form>
    </FormDialog>
  );
}

function StaffCard({ name, email, role, setSelectedStaff, id }) {
  const _role = role === ROLES[USER_ROLES.STORE_MEMBER] ? "Staff" : "Manager";
  const _bgColor =
    role === ROLES[USER_ROLES.STORE_MEMBER] ? "bg-primary" : "bg-success";

  const _edit = () => {
    if (_role === "Staff") setSelectedStaff(id);
  };

  return (
    <div className="py-4 pr-8">
      <div className="mx-auto h-full w-96 overflow-hidden rounded-md shadow-lg">
        <div className="relative p-6 pt-20 pb-10">
          <div
            className={classNames(
              "absolute left-0 top-0 rounded-br-md shadow-md",
              _bgColor
            )}
          >
            <p className="w-36 p-3 text-center text-xs font-semibold text-white">
              {_role}
            </p>
          </div>
          <div className="absolute right-4 top-2">
            <CustomMenu edit={_edit} editDisabled={_role !== "Staff"} />
          </div>
          <div className="flex items-center">
            <div className="ml-4">
              <h4 className="ellipsis text-base font-semibold" title={name}>
                {name}
              </h4>
              <p
                className="ellipsis mt-2 text-xs font-semibold text-black/40"
                title={email}
              >
                {email}
              </p>
            </div>
          </div>
          <div className="ml-20"></div>
        </div>
      </div>
    </div>
  );
}

function Staff() {
  const storeDetails = useStoreDetails();

  const {
    data: queryData,
    isLoading: queryLoading,
    isFetching: queryFetching,
    refetch,
  } = useQuery(["/staff", { storeId: storeDetails?.id }], getStaffByStoreId, {
    enabled: Boolean(storeDetails?.id),
  });

  const { mutateAsync: updateStaff, isLoading: staffUpdateLoading } =
    useMutation(edit, {
      onError: showErrorToast,
    });

  const { mutateAsync: createStaffManager, isLoading: managerMutationLoading } =
    useMutation(createManager, {
      onError: showErrorToast,
    });

  const [open, setOpen] = useBoolean();
  const [selectedStaff, setSelectedStaff] = useState(null);

  const { role } = useAuth();
  const notStaffMember = [
    USER_ROLES.HEADOFFICE_ADMIN,
    USER_ROLES.HEADOFFICE_MANAGER,
    USER_ROLES.STORE_MANAGER,
  ].includes(role);

  const loading =
    queryLoading ||
    queryFetching ||
    managerMutationLoading ||
    staffUpdateLoading;

  const data = useMemo(() => {
    return (
      queryData?.data?.map(({ name, username, role, ...d }) => ({
        ...d,
        name: name || username,
        role: ROLES[role],
      })) || []
    );
  }, [queryData]);

  const dataForDialog = data.find(({ id }) => id === selectedStaff);

  useEffect(() => {
    if (selectedStaff) {
      setOpen.on();
    }
    // eslint-disable-next-line
  }, [selectedStaff]);

  const openDialog = () => {
    setOpen.on();
  };

  const closeDialog = () => {
    if (!loading) {
      setOpen.off();
      setSelectedStaff(null);
    }
  };

  const onSubmit = async ({ data, reset }) => {
    try {
      let { role, name, color, pin, manager } = data;
      if (!storeDetails?.id) return;
      if (!selectedStaff) {
        // Add functionality
        if (role === "member") {
          await updateStaff({
            method: "POST",
            url: "/staffs",
            data: {
              store: {
                id: storeDetails.id,
              },
              name,
              color,
              pin,
            },
          });
          toast.success("Created new staff member");
        } else if (role === "manager") {
          // TODO: implement it
          // await createStaffManager({
          //   data: {
          //     store: {
          //       id: storeDetails.id,
          //     },
          //     name,
          //     color,
          //     pin,
          //     ...manager,
          //   },
          // });
          // toast.success("Created new staff manager");
        }
      } else {
        // Edit functionality
        if (role === "member") {
          await updateStaff({
            method: "PUT",
            url: `/staffs/${data.id}`,
            data: {
              name,
              color,
              pin,
              store: {
                id: storeDetails.id,
              },
            },
          });
          toast.success("Updated staff member");
        }
      }
      await refetch();
      reset();
      closeDialog();
    } catch (error) {}
  };

  const handleDelete = async ({ data, reset }) => {
    try {
      let { role, id } = data;
      if (role === "member") {
        await updateStaff({
          method: "DELETE",
          url: `/staffs/${id}`,
        });
        toast.success("Deleted staff member");
        await refetch();
        reset();
        closeDialog();
      }
    } catch (error) {}
  };

  return (
    <>
      <StaffDetailsDialog
        open={open}
        closeDialog={closeDialog}
        onSubmit={onSubmit}
        loading={loading}
        data={dataForDialog || null}
        action={dataForDialog ? "edit" : "add"}
        handleDelete={handleDelete}
      />
      <h2 className="title mb-2">Users</h2>
      <div className="mt-2 flex flex-wrap">
        {queryLoading ? (
          <Spinner className="mx-auto mt-32 h-10 w-10" />
        ) : (
          <>
            {data.map((d, idx) => (
              <StaffCard {...d} key={idx} setSelectedStaff={setSelectedStaff} />
            ))}
            {notStaffMember && (
              <AddNewCard
                onClick={openDialog}
                disabled={loading}
                innerCardStyles="py-7"
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Staff;
