import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import AddNewCard from "../components/add-new-card";
import FormDialog from "../components/dialogs/form-dialog";
import Filters from "../components/filters";
import Spinner from "../components/spinner";
import StoreCard from "../components/store-list/store-card";
import StoreDetailsForm from "../components/store-list/store-details-form";
import StoreSettingsDialog from "../components/store-list/store-settings-dialog";
import useBoolean from "../hooks/useBoolean";
import useCheckAuthRoles from "../hooks/useCheckAuthRoles";
import useDebounce from "../hooks/useDebounce";
import { useAuth } from "../utils/auth";
import { USER_ROLES } from "../utils/constants";
import { showErrorToast } from "../utils/helpers";
import { createStore, edit } from "../utils/mutations";
import { getHeadoffice, getTagsByHeadofficeId } from "../utils/queries";

const SORT_OPTIONS = [
  {
    id: "name-asc",
    label: "Name (ascending)",
  },
  {
    id: "name-desc",
    label: "Name (descending)",
  },
  {
    id: "createdAt-desc",
    label: "Date Created (newest)",
  },
  {
    id: "createdAt-asc",
    label: "Date Created (oldest)",
  },
];

function StoreList() {
  useCheckAuthRoles({
    roles: [USER_ROLES.HEADOFFICE_MANAGER, USER_ROLES.HEADOFFICE_ADMIN],
    checkStoreAdmin: true,
    shouldRedirect: true,
  });

  const [open, setOpen] = useBoolean();
  const [selectedStore, setSelectedStore] = useState(null);

  const [sortBy, setSortBy] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 250);

  const { data, role, setData } = useAuth();
  const headoffice = data?.manager?.headoffice;

  const isHeadofficeManager = [
    USER_ROLES.HEADOFFICE_MANAGER,
    USER_ROLES.HEADOFFICE_ADMIN,
  ].includes(role);

  const {
    data: queryData,
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery,
    refetch,
  } = useQuery(
    ["/headoffice", { headofficeId: headoffice?.id }],
    getHeadoffice,
    { enabled: Boolean(headoffice?.id) }
  );

  const {
    data: tagsData,
    isLoading: tagsLoadingQuery,
    isFetching: tagsFetchingQuery,
    refetch: refetchTags,
  } = useQuery(
    ["/get-tags", { headofficeId: headoffice?.id }],
    getTagsByHeadofficeId,
    {
      enabled: Boolean(headoffice?.id && isHeadofficeManager),
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      address: "",
      ip: "",
      email: "",
      startTime: "",
      endTime: "",
      tags: [],
    },
  });

  const { mutate, isLoading: isLoadingMutation } = useMutation(createStore, {
    onSuccess: async () => {
      await refetch();
      reset();
      setOpen.off();
      toast.success("Created new store");
    },
    onError: showErrorToast,
  });

  const { mutateAsync: addTag, isLoading: addTagLoading } = useMutation(edit, {
    onError: showErrorToast,
    onSuccess: async () => {
      await refetchTags();
      toast.success("Created new tag");
    },
  });

  const loading =
    isLoadingQuery ||
    isFetchingQuery ||
    isLoadingMutation ||
    addTagLoading ||
    tagsLoadingQuery ||
    tagsFetchingQuery;

  const storesData = useMemo(() => {
    if (!queryData) return [];
    return queryData.stores.map((store) => ({
      ...store,
      tags: store.tags.map((tag) => tag.id),
    }));
  }, [queryData]);

  useEffect(() => {
    if (queryData)
      setData({
        ...data,
        manager: { ...data.manager, stores: storesData },
      });
    // eslint-disable-next-line
  }, [queryData]);

  const openDialog = () => {
    reset();
    setOpen.on();
  };

  const closeDialog = () => {
    if (!loading) {
      setOpen.off();
    }
  };

  const onSubmit = (data) => {
    let { startTime, endTime } = data;
    let sTime = dayjs("1/1/1 " + startTime).format("HH:mm:ss");
    let eTime = dayjs("1/1/1 " + endTime).format("HH:mm:ss");
    if (headoffice?.id)
      mutate({
        data: {
          ...data,
          startTime: sTime,
          endTime: eTime,
          headoffice: {
            id: headoffice.id,
          },
        },
      });
  };

  const createTag = (tagName) => {
    if (!tagName?.trim()) return;
    addTag({
      method: "POST",
      url: "/tags",
      data: {
        store: {
          id: selectedStore,
        },
        name: tagName,
        headoffice: { id: headoffice.id },
      },
    });
  };

  return (
    <>
      {/* Add Store Dialog */}
      <FormDialog
        open={open}
        onClose={closeDialog}
        title="Add Store"
        disableClose
      >
        <StoreDetailsForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          loading={loading}
          open={open}
          errors={errors}
          closeDialog={closeDialog}
          action="add"
          getValues={getValues}
          control={control}
          setValue={setValue}
          createTag={createTag}
          tagsData={tagsData}
        />
      </FormDialog>

      {/* Store Settings Dialog */}
      <StoreSettingsDialog
        data={storesData.find(({ id }) => id === selectedStore)}
        setData={setSelectedStore}
        createTag={createTag}
        tagsData={tagsData?.data}
        loading={loading}
      />

      <div className="p-6">
        <h2 className="title mb-2">Stores</h2>
        <Filters
          search={search}
          setSearch={setSearch}
          loading={loading}
          setSortBy={setSortBy}
          sortBy={sortBy}
          sortOptions={SORT_OPTIONS}
        />
        <div className="mt-2 flex flex-wrap">
          {isLoadingQuery ? (
            <Spinner className="mx-auto mt-32 h-10 w-10" />
          ) : (
            <>
              {storesData
                .filter((store) =>
                  store.name
                    .toLowerCase()
                    .includes(debouncedSearch.toLowerCase())
                )
                .sort((a, b) => {
                  let [_sortBy, type] = sortBy?.split("-") || [];
                  let sort = 1;
                  if (_sortBy === "createdAt")
                    sort = dayjs(a.createdAt).isAfter(dayjs(b.createdAt))
                      ? 1
                      : -1;
                  else sort = a[_sortBy] > b[_sortBy] ? 1 : -1;
                  return type === "desc" ? -sort : sort;
                })
                .map((store) => (
                  <StoreCard
                    {...store}
                    key={store.id}
                    setSelectedStore={setSelectedStore}
                  />
                ))}
              {[
                USER_ROLES.HEADOFFICE_ADMIN,
                USER_ROLES.HEADOFFICE_MANAGER,
              ].includes(role) && (
                <AddNewCard onClick={openDialog} disabled={loading} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StoreList;
