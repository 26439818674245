import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import { classNames } from "../utils/helpers";

export default function TagInput({
  loading,
  label,
  value,
  nameKey = "name",
  data,
  selectedOptionStyle,
  onChange,
  placeholder = "Select an option",
  multiple = true,
  createTag = () => {},
}) {
  const [query, setQuery] = useState("");

  const filteredData =
    query === ""
      ? data
      : data.filter((person) =>
          person[nameKey]
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const renderTags = () => {
    if (value?.length)
      return (
        <div className="mt-4 flex flex-wrap items-center">
          {value.map((d) => {
            let record = data.find((v) => v.id === d);
            if (!record) return null;
            return (
              <span
                key={d}
                className={classNames(
                  "mb-2 mr-3 flex cursor-pointer items-center rounded-full bg-black/10 py-[0.375rem] px-4 pr-3 text-xs font-semibold",
                  selectedOptionStyle,
                  loading && "pointer-events-none"
                )}
                onClick={() => {
                  onChange(value.filter((v) => v !== d));
                }}
              >
                {record?.[nameKey]}
                <XIcon className="ml-2 h-4 w-4 rounded-full border border-error p-[0.125rem] text-error" />
              </span>
            );
          })}
        </div>
      );

    return null;
  };

  return (
    <Combobox value={value} onChange={onChange} multiple={multiple}>
      <div className="relative mt-1">
        <label className="input-label z-[1]">{label}</label>
        <div
          className={classNames(
            "relative w-full cursor-pointer rounded-md border border-black/30 bg-white py-3 px-5 text-left focus:border-black",
            loading && "cursor-not-allowed"
          )}
        >
          <Combobox.Input
            className="w-full border-none p-0 pr-12 text-sm"
            readOnly={loading}
            displayValue={
              multiple
                ? null
                : (_value) => data.find((v) => v.id === _value)?.[nameKey]
            }
            placeholder={placeholder}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredData.length === 0 && query !== "" ? (
              <div>
                <p className="relative cursor-default select-none py-2 px-5 text-sm text-error">
                  No Tags Found
                </p>
                <span
                  className={classNames(
                    "btn-primary mx-5 my-2 inline-block",
                    loading
                      ? "pointer-events-none cursor-not-allowed"
                      : "cursor-pointer"
                  )}
                  onClick={(e) => {
                    // closeDialog();
                    createTag(query);
                    e.preventDefault();
                  }}
                >
                  Create Tag
                </span>
              </div>
            ) : (
              filteredData.map((_data) => (
                <Combobox.Option
                  key={_data.id}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                      active ? "bg-blue-100 text-primary" : "text-gray-900"
                    }`
                  }
                  value={_data.id}
                >
                  {({ selected }) => {
                    return (
                      <>
                        <span
                          className={`block truncate text-sm ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {_data[nameKey]}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    );
                  }}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
      {renderTags()}
    </Combobox>
  );
}
