import React from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { classNames } from "../utils/helpers";

function AddNewCard({
  onClick,
  disabled,
  className = "",
  innerCardStyles = "",
}) {
  return (
    <div className="py-4">
      <div
        className={classNames(
          "mx-auto h-full w-96 overflow-hidden rounded-md border-2 border-dotted border-primary shadow-lg",
          className
        )}
      >
        <div
          className={classNames(
            "flex h-full cursor-pointer items-center justify-center bg-white p-6 py-24",
            innerCardStyles,
            disabled && "cursor-not-allowed"
          )}
          onClick={!disabled && onClick}
        >
          <div
            className={classNames(
              "rounded-full bg-primary/20 p-5",
              disabled && "bg-black/20"
            )}
          >
            <PlusIcon
              className={classNames(
                "h-16 w-16 text-primary",
                disabled && "text-black"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewCard;
