import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../utils/auth";
import { getAdminHeadoffice } from "../../utils/queries";
import Spinner from "../spinner";
import Table from "../table";

function Store() {
  const { data } = useAuth();
  const headoffice = data?.manager?.headoffice;

  const {
    data: headofficeData,
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery,
  } = useQuery(
    ["/admin/headoffice", { headofficeId: headoffice?.id }],
    getAdminHeadoffice,
    { enabled: Boolean(headoffice?.id) }
  );

  const loading = isLoadingQuery || isFetchingQuery;

  const columns = useMemo(() => {
    return [
      { Header: "Store Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Address", accessor: "address" },
      {
        Header: "Starting Time",
        accessor: "startTime",
        Cell: ({ row }) => {
          let value = row.values["startTime"];
          return (
            <span>
              {value ? dayjs(value, "HH:mm:ss").format("hh:mm A") : "-"}
            </span>
          );
        },
      },
      {
        Header: "Closing Time",
        accessor: "endTime",
        Cell: ({ row }) => {
          let value = row.values["endTime"];
          return (
            <span>
              {value ? dayjs(value, "HH:mm:ss").format("hh:mm A") : "-"}
            </span>
          );
        },
      },
    ];
  }, []);

  const rows = useMemo(() => {
    return headofficeData?.stores || [];
  }, [headofficeData]);

  return (
    <>
      {loading ? (
        <div className="fixed top-0 left-0 z-10 h-full w-full bg-white/40">
          <Spinner className="m-auto mt-[45vh] h-10 w-10" />
        </div>
      ) : null}
      <h2 className="title mb-6">Stores</h2>
      <Table columns={columns} data={rows} />
    </>
  );
}

export default Store;
