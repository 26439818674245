import { Tab } from "@headlessui/react";
import { Fragment, useMemo } from "react";
import Noticeboard from "../components/store/noticeboard";
import ArchiveNoticeboard from "../components/store/noticeboard/archive";
import Tasks from "../components/store/tasks";
import Container from "../layouts/container";
import { useAuth } from "../utils/auth";
import { USER_ROLES } from "../utils/constants";
import { classNames } from "../utils/helpers";

function Store() {
  const { role } = useAuth();
  const notStaffMember = [
    USER_ROLES.HEADOFFICE_ADMIN,
    USER_ROLES.HEADOFFICE_MANAGER,
    USER_ROLES.STORE_MANAGER,
  ].includes(role);

  const TABS = useMemo(() => {
    return [
      {
        label: "Task Calendar",
        key: "tasks",
        component: <Tasks />,
      },
      {
        label: "Notice Board",
        key: "noticeboard",
        component: <Noticeboard />,
      },
      notStaffMember && {
        label: "Archived Notice Board",
        key: "archived-noticeboard",
        component: <ArchiveNoticeboard />,
      },
    ].filter((tab) => tab);
  }, [notStaffMember]);

  return (
    <Container>
      <Tab.Group>
        <Tab.List className="bg-white px-6 pt-2 pb-4 shadow-md">
          {TABS.map(({ label, key, disabled }) => (
            <Tab as={Fragment} key={key} disabled={disabled}>
              {({ selected }) => (
                <button
                  className={classNames(
                    "mr-4 min-w-[8rem] rounded-md py-3 px-6 text-xs font-semibold uppercase shadow-md transition-all duration-300 ease-in-out hover:bg-cool-orange hover:text-white",
                    selected ? "bg-cool-orange text-white" : "bg-white",
                    disabled && "cursor-not-allowed "
                  )}
                  disabled={disabled}
                >
                  {label}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="p-6">
          {TABS.map(({ component }) => (
            <Tab.Panel>{component}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Container>
  );
}

export default Store;
