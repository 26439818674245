import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../utils/auth";

function useStoreDetails() {
  const { storeId } = useParams();
  const queryClient = useQueryClient();
  const { data } = useAuth();
  const [storeData, setStoreData] = useState(null);

  useEffect(() => {
    queryClient.setQueryData(["/store", { storeId }], () => {
      let details = null;
      if (data?.store?.id) {
        details = data.store;
      } else {
        details = data?.manager?.stores?.find(({ id }) => id === storeId);
      }
      setStoreData(details);
      return details;
    });
    // eslint-disable-next-line
  }, [data]);

  return storeData;
}

export default useStoreDetails;
