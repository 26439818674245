import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { USER_ROLES } from "../utils/constants";
import { redirectToLogin } from "../utils/helpers";

function useCheckAuthRoles({
  roles = [],
  checkStoreAdmin = false,
  shouldRedirect = true,
}) {
  const navigate = useNavigate();
  const { role, data } = useAuth();

  // Checking if user is STORE_ADMIN and has access to more than one store, if yes then user can access StoreList page
  const storeAdminAuthorized = checkStoreAdmin
    ? role === USER_ROLES.STORE_MANAGER && data?.manager?.stores?.length > 1
    : false;
  const isAuthorized = roles.includes(role) || storeAdminAuthorized;

  const storeId = data?.store?.id || "";

  const redirect = (role) => {
    let redirectTo = {
      [USER_ROLES.STORE_MANAGER]:
        checkStoreAdmin && storeAdminAuthorized
          ? "/store"
          : `/store/${storeId}`,
      [USER_ROLES.STORE_MEMBER]: `/store/${storeId}`,
      [USER_ROLES.HEADOFFICE_ADMIN]: "/headoffice",
      [USER_ROLES.HEADOFFICE_MANAGER]: "/store",
    };
    if (redirectTo[role]) return navigate(redirectTo[role]);
    redirectToLogin();
  };

  useEffect(() => {
    if (isAuthorized) return null;
    else if (shouldRedirect) redirect(role);
    // eslint-disable-next-line
  }, [role, isAuthorized, data]);

  return isAuthorized;
}

export default useCheckAuthRoles;
