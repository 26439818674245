import { DropDownList, MultiSelect } from "@syncfusion/ej2-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  Agenda,
  Inject,
  ScheduleComponent,
  TimelineMonth,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import dayjs from "dayjs";
import * as React from "react";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useStoreDetails from "../../../hooks/useStoreDetails";
import { useAuth } from "../../../utils/auth";
import { USER_ROLES } from "../../../utils/constants";
import { showErrorToast } from "../../../utils/helpers";
import { edit } from "../../../utils/mutations";
import {
  getStaffByStoreId,
  getTasksByStoreId,
  getTeamsByStoreId,
} from "../../../utils/queries";
import FormDialog from "../../dialogs/form-dialog";
import InputGroup from "../../input-group";
import Spinner from "../../spinner";
import { ASSIGNED_OPTIONS, FEILDS, TASK_STATUS } from "./constants";
import {
  filterStaff,
  getAssignedTo,
  getStoreTimings,
  getTaskStatus,
  parseStaff,
} from "./helpers";
import "./index.css";

function CustomDialog({
  buttons = [],
  okText = "Ok",
  onOkClick,
  cancelText = "Cancel",
  title,
  visible,
  closeDialog,
  children,
  loading = false,
  ...rest
}) {
  const close = () => {
    if (!loading) closeDialog();
  };
  const BUTTONS = buttons.length
    ? buttons
    : [
        {
          click: close,
          buttonModel: {
            content: cancelText,
            disabled: loading,
          },
        },
        {
          click: onOkClick,
          buttonModel: {
            isPrimary: true,
            content: okText,
            disabled: loading,
          },
        },
      ];
  return (
    <DialogComponent
      visible={visible}
      isModal="true"
      buttons={BUTTONS}
      showCloseIcon={true}
      target={document.body}
      width="400px"
      header={title}
      close={close}
      {...rest}
    >
      {children}
    </DialogComponent>
  );
}

function MarkCompeleteDialog({
  markAsComplete,
  closeMarkCompletedDialog,
  taskToBeCompleted,
  loading,
  tasksQueryData,
}) {
  const [pin, setPin] = useState("");

  const { label, data } = getAssignedTo({
    ...taskToBeCompleted,
    tasksData: tasksQueryData?.data || [],
  });
  const assignedData = data ? `(${data})` : null;

  const onClose = () => {
    setPin("");
    closeMarkCompletedDialog();
  };

  return (
    <FormDialog
      open={Boolean(taskToBeCompleted?.id)}
      onClose={onClose}
      title={null}
      disableClose
      modalStyles="w-full max-w-lg p-0"
    >
      <h3 className="block border-b-[1px] border-black/20 px-6 pb-3 text-xl leading-6 text-gray-900">
        {taskToBeCompleted?.title}
      </h3>
      <div className="p-6">
        <p className="flex pb-6 text-sm">
          <span className="mr-2 min-w-[90px] font-semibold">
            {taskToBeCompleted?.description ? "Description" : "Title"}:
          </span>
          <span>
            {taskToBeCompleted?.description || taskToBeCompleted?.title}
          </span>
        </p>
        <p className="flex text-sm">
          <span className="mr-2 min-w-[90px] font-semibold">Assigned To:</span>
          <span>
            {label} {assignedData}
          </span>
        </p>
      </div>
      <div className="flex items-center justify-between px-6 py-3">
        <button
          className="btn-secondary w-32"
          disabled={loading}
          onClick={onClose}
        >
          Cancel
        </button>
        <InputGroup
          title="PIN"
          htmlFor="pin"
          id="pin"
          placeholder="Enter PIN"
          disabled={loading}
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          type="password"
          className="mx-3 w-[200px]"
          inputStyles="py-2"
        />
        <button
          className="btn-primary w-32"
          disabled={loading}
          onClick={() => {
            markAsComplete({ pin });
          }}
        >
          Mark Complete
        </button>
        <div className="flex items-center"></div>
      </div>
    </FormDialog>
  );
}

function Tasks() {
  var scheduler = {},
    titleInput = {};

  const { role } = useAuth();
  const storeDetails = useStoreDetails();
  const [validationMessage, setValidationMessage] = useState(false);
  const [taskToBeCompleted, setTaskToBeCompleted] = useState(null);

  const storeTimings = getStoreTimings({
    start: storeDetails?.startTime,
    end: storeDetails?.endTime,
  });

  const canEdit = [
    USER_ROLES.HEADOFFICE_ADMIN,
    USER_ROLES.STORE_ADMIN,
    USER_ROLES.HEADOFFICE_MANAGER,
  ].includes(role);

  const { data: staffQueryData, isLoading: staffQueryLoading } = useQuery(
    ["/staff", { storeId: storeDetails?.id }],
    getStaffByStoreId,
    {
      enabled: Boolean(storeDetails?.id),
    }
  );
  const { data: teamsQueryData, isLoading: teamsQueryLoading } = useQuery(
    ["/teams", { storeId: storeDetails?.id }],
    getTeamsByStoreId,
    {
      enabled: Boolean(storeDetails?.id),
    }
  );
  const {
    data: tasksQueryData,
    isLoading: tasksQueryLoading,
    isFetching: tasksQueryFetching,
    refetch,
  } = useQuery(["/tasks", { storeId: storeDetails?.id }], getTasksByStoreId, {
    enabled: Boolean(storeDetails?.id),
  });

  const staffData = useMemo(() => {
    let data = staffQueryData?.data.map(
      ({ name, username, role: staffRole, ...d }, idx) => ({
        ...d,
        name: name || username,
        role: staffRole,
        type: staffRole === USER_ROLES.STORE_MEMBER ? "Staff" : "Manager",
        _id: idx + 1,
      })
    );
    return data || [];
  }, [staffQueryData]);

  const { mutateAsync: updateTask, isLoading: staffUpdateLoading } =
    useMutation(edit, {
      onError: showErrorToast,
      onSettled: () => refetch(),
    });

  const eventsData = useMemo(() => {
    let data = tasksQueryData?.data || [];
    data = data.map((task) => {
      let { staffs, teams, managers, repeat, end } = task;
      let selectedStaffs = parseStaff({
        staffs: staffs.map(({ id }) => id),
        staffData,
        staffType: "Staff",
      });
      let selectedManagers = parseStaff({
        staffs: managers.map(({ id }) => id),
        staffData,
        staffType: "Manager",
      });
      return {
        ...task,
        repeat: repeat && repeat === "never" ? null : repeat,
        staffs: [...selectedStaffs, ...selectedManagers],
        teams: teams.map(({ id }) => id),
        actualDate: dayjs(end).local().format("YYYY-MM-DD"), // Passing this to only show the actual tasks (Which are stored in DB).
      };
    });
    return data;
    // eslint-disable-next-line
  }, [tasksQueryData, staffQueryData]);

  const loading =
    staffQueryLoading ||
    teamsQueryLoading ||
    tasksQueryLoading ||
    tasksQueryFetching ||
    staffUpdateLoading;

  const verifyEventDetails = (data) => {
    let result = { isValid: true, message: "" };
    let { assignedTo, staffs, teams, title, start, end } = data;
    if (!title) {
      result.isValid = false;
      result.message = "Title is required";
    } else if (!start || !end) {
      result.isValid = false;
      result.message = "Start and End time of task is required";
    } else if (!assignedTo) {
      result["isValid"] = false;
      result["message"] = "Please assign task to someone";
    } else if (assignedTo === ASSIGNED_OPTIONS.STAFF.value && !staffs?.length) {
      result["isValid"] = false;
      result["message"] = "Please assign task to any staff";
    } else if (assignedTo === ASSIGNED_OPTIONS.TEAMS.value && !teams?.length) {
      result["isValid"] = false;
      result["message"] = "Please assign task to any teams";
    }
    return result;
  };

  const insertElement = ({ id, element, name }) => {
    let row = document.createElement("div");
    row.className = "custom-field-row";
    row.id = id;

    let formElement = document.querySelector(".e-schedule-form");
    formElement.firstChild.insertBefore(
      row,
      formElement.firstChild.childNodes[1]
    );

    let container = document.createElement("div");
    container.className = "pb-[12px] custom-element-container";

    let inputEle = document.createElement("input");
    inputEle.className = "e-field";
    inputEle.name = name;
    inputEle.required = true;

    container.appendChild(inputEle);
    row.appendChild(container);

    element.appendTo(inputEle);
    inputEle.setAttribute("name", name);
  };

  const removeAssigneeSelector = ({ value }) => {
    let hide = value === ASSIGNED_OPTIONS.ALL.value;
    let staffAssigneeElement = document.querySelector(
      "#staff-assignees-custom-select"
    );
    let teamAssigneeElement = document.querySelector(
      "#teams-assignees-custom-select"
    );
    if (staffAssigneeElement)
      staffAssigneeElement.style.display =
        hide || value === ASSIGNED_OPTIONS.TEAMS.value ? "none" : "block";
    if (teamAssigneeElement)
      teamAssigneeElement.style.display =
        hide || value === ASSIGNED_OPTIONS.STAFF.value ? "none" : "block";
  };

  const handlePopupOpen = (args) => {
    let { data, type, element } = args;
    if (type === "RecurrenceAlert") {
      // Double click handling on event

      // Hiding RecurrenceAlert modal
      args.cancel = true;
      // Opening event editor
      scheduler.openEditor(data, "Save", true);
    } else if (type === "Editor") {
      if (!canEdit || data?.actualDate) {
        args.cancel = true;
        toast.error("Whoops, can't open the editor");
        return null;
      }

      scheduler.eventWindow.recurrenceEditor.frequencies = [
        "none",
        "daily",
        "weekly",
        "monthly",
      ];

      let assignedTo = data.assignedTo || "all";

      if (!element.querySelector(".custom-field-row")) {
        // Create required custom elements
        let multiselectListStaff = new MultiSelect({
          dataSource: staffData,
          fields: { text: "name", value: "_id", groupBy: "type" },
          value: data.staffs || [],
          floatLabelType: "Always",
          placeholder: "Select Staff Assignees",
        });
        insertElement({
          id: "staff-assignees-custom-select",
          element: multiselectListStaff,
          name: "staffs",
        });

        let multiselectListTeams = new MultiSelect({
          dataSource: teamsQueryData?.data || [],
          fields: { text: "name", value: "id" },
          value: data.teams || [],
          floatLabelType: "Always",
          placeholder: "Select Team Assignees",
        });
        insertElement({
          id: "teams-assignees-custom-select",
          element: multiselectListTeams,
          name: "teams",
        });

        let dropdownList = new DropDownList({
          dataSource: Object.values(ASSIGNED_OPTIONS),
          fields: { text: "text", value: "value" },
          value: assignedTo,
          floatLabelType: "Always",
          placeholder: "Assigned To",
          change: (e) => {
            let value = e.value;
            removeAssigneeSelector({ value });
          },
        });
        insertElement({
          id: "assignedTo-custom-select",
          element: dropdownList,
          name: "assignedTo",
        });
      }

      // Initially checking if assignee is required to be hidden
      removeAssigneeSelector({ value: assignedTo });
    }
  };

  const actionHandler = async (args) => {
    let { requestType, data } = args;
    let eventDetails = data?.[0]?.occurrence || data?.[0] || data || null;
    if (eventDetails && canEdit) {
      if (["eventChange", "eventCreate"].includes(requestType)) {
        let { isValid, message } = verifyEventDetails(eventDetails);
        if (!isValid) {
          setValidationMessage(message);
          args.cancel = true;
        } else
          await editTask(
            eventDetails,
            requestType === "eventCreate" ? "add" : "edit"
          );
      } else if (requestType === "eventRemove") {
        scheduler.closeQuickInfoPopup();
        await deleteTask(eventDetails);
      }
    }
  };

  const deleteTask = async (eventDetails) => {
    await updateTask({
      url: `/tasks/${eventDetails.id}`,
      method: "DELETE",
    });
    toast.success("Deleted event");
  };

  const editTask = async (eventDetails, action = "add") => {
    let dataToBeSent = eventDetails;
    let selectedManagers = [];
    let selectedStaffs = [];
    let selectedTeams = [];
    let { assignedTo, teams, staffs, title, description, start, end, repeat } =
      dataToBeSent;
    if (assignedTo === ASSIGNED_OPTIONS.ALL.value) {
      selectedManagers = staffQueryData?.managers?.map(({ id }) => id);
      selectedStaffs = staffQueryData?.staff?.map(({ id }) => id);
      selectedTeams = [];
    } else if (assignedTo === ASSIGNED_OPTIONS.STAFF.value) {
      selectedManagers = filterStaff({
        staffData,
        staffs,
        staffType: "Manager",
      });
      selectedStaffs = filterStaff({
        staffData,
        staffs,
        staffType: "Staff",
      });
      selectedTeams = [];
    } else if (assignedTo === ASSIGNED_OPTIONS.TEAMS.value) {
      selectedManagers = [];
      selectedStaffs = [];
      selectedTeams = teams;
    }

    dataToBeSent = {
      title,
      description,
      start,
      end,
      managers: selectedManagers,
      staffs: selectedStaffs,
      teams: selectedTeams,
      stores: [],
      assignedTo,
      store: {
        id: storeDetails.id,
      },
      repeat: repeat || "never",
    };

    let url = action === "add" ? "/tasks" : `/tasks/${eventDetails.id}`;
    let method = action === "add" ? "POST" : "PUT";
    let message = action === "add" ? "Added new task" : "Updated task";

    await updateTask({
      data: dataToBeSent,
      url,
      method,
    });
    toast.success(message);
  };

  const renderEvent = (args) => {
    let { data } = args;
    // Not rendering any events other than events stored in DB (coming in response)
    if (!dayjs(data.actualDate).isSame(dayjs(data.end), "day")) {
      args.cancel = true;
    }
    let status = getTaskStatus({
      completedBies: args.data.completedBies,
      start: args.data.start,
      end: args.data.end,
    });
    if (status === TASK_STATUS.NOT_COMPLETED) {
      args.element.classList.add("incomplete-task-blinking");
    } else if (status === TASK_STATUS.PENDING) {
      args.element.classList.add("pending-task-blinking");
    } else {
      args.element.style.backgroundColor = status.color;
      args.element.style.borderColor = status.color;
    }
  };

  const handleCellEvents = (e) => {
    let taskDetails = getSlotData();
    if (e.target.id === "add") {
      scheduler.addEvent(taskDetails);
    } else if (e.target.id === "more-details") {
      scheduler.openEditor(taskDetails, "Add", true);
    }
  };

  const handletasksEvents = (e) => {
    let taskDetails = scheduler.activeEventData.event;
    if (e.target.id === "delete") {
      scheduler.currentAction = null;
      scheduler.quickPopup.openDeleteAlert();
    } else if (e.target.id === "more-details") {
      scheduler.openEditor(taskDetails, "Save", true);
    } else if (e.target.id === "mark-complete") {
      setTaskToBeCompleted(taskDetails);
    }
  };

  const renderFooter = (props) => {
    let isCell = props.elementType === "cell";
    let eventDetails = isCell ? getSlotData() : scheduler.activeEventData.event;
    let taskStatus = getTaskStatus(eventDetails);
    let taskPending = taskStatus.value === TASK_STATUS.PENDING.value;
    let taskCompleted = taskStatus.value === TASK_STATUS.COMPLETED.value;
    if (isCell && !canEdit) {
      let footer = document.querySelector(".e-popup-footer");
      footer.style.display = "none";
      return null;
    }
    let isSameDay = dayjs(eventDetails.start).isSame(dayjs(), "day");
    let showMarkCompleteButton = !taskCompleted && isSameDay;
    return isCell ? (
      <>
        <ButtonComponent
          id="more-details"
          cssClass="e-flat"
          content="More Details"
          onClick={handleCellEvents}
        />
        <ButtonComponent
          id="add"
          cssClass="e-flat"
          content="Add"
          isPrimary={true}
          onClick={handleCellEvents}
        />
      </>
    ) : (
      <div className="event-footer">
        {canEdit && (
          <ButtonComponent
            id="delete"
            cssClass="e-flat"
            content="Delete"
            onClick={handletasksEvents}
          />
        )}
        {canEdit && taskPending && (
          <ButtonComponent
            id="more-details"
            cssClass="e-flat"
            content="More Details"
            onClick={handletasksEvents}
          />
        )}
        {showMarkCompleteButton && (
          <ButtonComponent
            id="mark-complete"
            cssClass="e-flat"
            content="Mark Complete"
            isPrimary
            onClick={handletasksEvents}
          />
        )}
      </div>
    );
  };

  const renderContent = (props) => {
    const { start, end, description, elementType, completedBies } = props;
    let isCell = elementType === "cell";
    let startTime = dayjs(start);
    let endTime = dayjs(end);
    let { label, data } = getAssignedTo({
      ...props,
      tasksData: tasksQueryData?.data,
    });
    let assignedData = data ? `(${data})` : null;
    return (
      <div className="quick-info-content">
        {isCell ? null : (
          <>
            {description && (
              <p className="flex pb-2 text-sm">
                <span className="mr-2 min-w-[90px] font-semibold">
                  Description:
                </span>
                <span className="word-break">{description}</span>
              </p>
            )}
            <p className="flex pb-2 text-sm">
              <span className="mr-2 min-w-[90px] font-semibold">
                Assigned To:
              </span>
              <span>
                {label} {assignedData}
              </span>
            </p>
            {completedBies?.length && (
              <div className="flex items-center pb-2">
                <span className="mr-2 min-w-[90px] text-sm font-semibold">
                  Completed By:
                </span>
                <div className="flex flex-wrap items-center">
                  {completedBies.map(({ name, id, type, color }) => (
                    <span
                      key={id + type}
                      className="my-1 mr-3 rounded-full py-[0.275rem] px-3 text-xs font-semibold text-white"
                      style={{ background: color || "#000" }}
                    >
                      {name}
                    </span>
                  ))}
                </div>
              </div>
            )}
            <p className="text-center text-sm">
              {startTime.format("DD MMMM[,] YYYY")} (
              {startTime.format("hh:mm A")} - {endTime.format("hh:mm A")})
            </p>
          </>
        )}
      </div>
    );
  };

  const getSlotData = () => {
    const cellDetails = scheduler.getCellDetails(
      scheduler.getSelectedElements()
    );
    return {
      Id: scheduler.getEventMaxID(),
      title: titleInput.value,
      start: new Date(+cellDetails.startTime),
      end: new Date(+cellDetails.endTime),
      description: "",
      staffs: [],
      teams: [],
      stores: [],
      assignedTo: null,
      completedBies: null,
    };
  };

  const markAsComplete = async ({ pin }) => {
    try {
      if (!pin || pin.length !== 4)
        return toast.error("Please enter a valid PIN");
      let taskDetails = scheduler.activeEventData.event;
      if (taskDetails) {
        let details = {
          ...taskDetails,
          staffs: taskDetails.staffs.map((id) =>
            staffData.find(({ _id }) => _id === id)
          ),
          managers: taskDetails.managers.map((id) =>
            staffData.find(({ _id }) => _id === id)
          ),
          teams: taskDetails.teams.map((teamId) =>
            teamsQueryData?.data?.find(({ id }) => id === teamId)
          ),
        };
        scheduler.closeQuickInfoPopup();
        await updateTask({
          data: {
            store: {
              id: storeDetails.id,
            },
            pin,
            task: details,
          },
          url: `/custom/complete-task/${taskDetails.id}`,
          method: "PUT",
        });
        toast.success("Task marked as completed");
      }
    } catch (e) {
      console.log(e);
    } finally {
      closeMarkCompletedDialog();
    }
  };

  const closeMarkCompletedDialog = () => {
    setTaskToBeCompleted(null);
  };

  return (
    <div>
      {loading ? (
        <div className="fixed top-0 left-0 z-10 h-full w-full bg-white/40">
          <Spinner className="m-auto mt-[45vh] h-10 w-10" />
        </div>
      ) : null}
      <h2 className="title mb-6">Tasks</h2>
      <div>
        {/* Validation Dialog component */}
        <CustomDialog
          buttons={[
            {
              click: () => {
                setValidationMessage(null);
              },
              buttonModel: {
                isPrimary: true,
                content: "Ok",
              },
            },
          ]}
          visible={Boolean(validationMessage)}
          title="Whoops!"
        >
          <div>
            {validationMessage}
            <br />
            <br />
          </div>
        </CustomDialog>
        {/* Mark Completed Dialog */}
        <MarkCompeleteDialog
          markAsComplete={markAsComplete}
          closeMarkCompletedDialog={closeMarkCompletedDialog}
          taskToBeCompleted={taskToBeCompleted}
          loading={loading}
          tasksQueryData={tasksQueryData}
        />
        <ScheduleComponent
          width="100%"
          height="calc(100vh - 180px)"
          style={{ minHeight: 600 }}
          selectedDate={new Date()}
          currentView="TimelineDay"
          eventSettings={{
            dataSource: eventsData,
            fields: FEILDS,
          }}
          ref={(ref) => (scheduler = ref)}
          showQuickInfo
          popupOpen={handlePopupOpen}
          eventRendered={renderEvent}
          actionBegin={actionHandler}
          quickInfoTemplates={{
            content: renderContent,
            footer: renderFooter,
          }}
          startHour={storeTimings.start}
          endHour={storeTimings.end}
          workHours={{
            highlight: true,
            start: storeTimings.start,
            end: storeTimings.end,
          }}
          cellClick={(args) => {
            if (canEdit) {
              // Closing the quick info popup
              args.cancel = true;
              // Opening editor popup
              scheduler.openEditor(
                {
                  Id: scheduler.getEventMaxID(),
                  title: null,
                  start: new Date(args.startTime),
                  end: new Date(args.endTime),
                  description: "",
                  staffs: [],
                  teams: [],
                  stores: [],
                  assignedTo: null,
                  completedBies: null,
                },
                "Add",
                true
              );
            } else {
              // Closing the quick info popup
              args.cancel = true;
              // Showing toast error
              toast.error("Please sign in to add tasks");
            }
          }}
          cellDoubleClick={(args) => {
            // Not allowed to add event if user is a STAFF MEMBER
            if (!canEdit) args.cancel = true;
          }}
        >
          <ViewsDirective>
            <ViewDirective option="TimelineDay" displayName="Day" />
            <ViewDirective option="TimelineWeek" displayName="Week" />
            <ViewDirective option="TimelineMonth" displayName="Month" />
            <ViewDirective option="Agenda" />
          </ViewsDirective>
          <Inject services={[TimelineViews, TimelineMonth, Agenda]} />
        </ScheduleComponent>
      </div>
    </div>
  );
}

export default Tasks;
