import { Listbox, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { classNames } from "../utils/helpers";

const Selector = ({
  value,
  onChange,
  data,
  placeholder,
  nameKey,
  loading,
  label,
  multiple = true,
  selectedOptionStyle = "",
}) => {
  const renderValue = () => {
    if (value?.length && Array.isArray(value))
      return value.map((d) => {
        let record = data.find((v) => v.id === d);
        if (!record) return null;
        return (
          <span
            key={d}
            className={classNames(
              "mr-3 flex items-center rounded-full bg-black/10 py-[0.375rem] px-4 pr-3 text-xs font-semibold",
              selectedOptionStyle,
              loading && "pointer-events-none"
            )}
            onClick={() => {
              onChange(value.filter((v) => v !== d));
            }}
          >
            {record?.[nameKey]}
            <XIcon className="ml-2 h-4 w-4 rounded-full border border-error p-[0.125rem] text-error" />
          </span>
        );
      });
    if (value?.length && value)
      return (
        <span
          className={classNames(
            "py-[0.375rem] text-sm font-semibold",
            selectedOptionStyle
          )}
        >
          {data.find((v) => v.id === value)?.[nameKey]}
        </span>
      );
    return (
      <p
        className={classNames(
          "py-1 text-sm text-black/50",
          selectedOptionStyle
        )}
      >
        {placeholder}
      </p>
    );
  };

  return (
    <Listbox
      // {...otherProps}
      value={value}
      onChange={(options) => {
        onChange(options);
      }}
      multiple={multiple}
      disabled={loading}
    >
      <div className="relative w-full">
        <label className="input-label z-[1]">{label}</label>
        <Listbox.Button
          className={classNames(
            "relative w-full cursor-pointer rounded-md border border-black/30 bg-white py-2 px-5 text-left focus:border-black",
            loading && "cursor-not-allowed"
          )}
        >
          <div className="flex flex-wrap items-center">{renderValue()}</div>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {data.map((d) => (
              <Listbox.Option
                key={d.id}
                className={({ active }) =>
                  `relative select-none py-2 pl-10 pr-4 ${
                    active ? "bg-blue-100 text-primary" : "text-gray-900"
                  } ${
                    loading || d.disabled
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`
                }
                value={d.id}
                disabled={loading || d.disabled}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate text-sm ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {d[nameKey]}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Selector;
