import React from "react";
import { useExpanded, useTable } from "react-table";

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useExpanded);
  return (
    <table
      {...getTableProps()}
      className="w-full overflow-hidden border text-left text-sm text-gray-500 shadow-sm"
    >
      <thead className="bg-cool-orange text-sm uppercase text-white">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  style: {
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth,
                  },
                })}
                className="px-6 py-4 text-center font-normal"
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="border-b bg-white hover:bg-gray-50 "
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps({
                      style: {
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        maxWidth: cell.column.maxWidth,
                      },
                    })}
                    className="px-6 py-4 text-center text-gray-800"
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
