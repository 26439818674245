import dayjs from "dayjs";
import { ASSIGNED_OPTIONS, TASK_STATUS } from "./constants";

export const getStoreTimings = (time) => {
  let { start, end } = time;

  if (!start || !end) return {};

  let [sHrs, sMins] = start.split(":");
  let [eHrs, eMins] = end.split(":");

  sMins = Number(sMins);
  eMins = Number(eMins);

  // If sMin === 0, then continue else round off to the nearest 30 mins
  if (sMins) {
    let sRemainder = 30 - (sMins % 30);
    sMins = sMins + sRemainder;
  }
  // If eMins === 0, then continue else round off to the nearest 30 mins
  if (eMins) {
    let eRemainder = 30 - (eMins % 30);
    eMins = eMins + eRemainder;
  }

  return {
    start: `${sHrs}:${sMins}`,
    end: `${eHrs}:${eMins}`,
  };
};

export const getTaskStatus = ({ completedBies, start, end }) => {
  let now = dayjs();
  let startTime = dayjs(start);
  let endTime = dayjs(end);
  if (completedBies?.length) {
    return TASK_STATUS.COMPLETED;
  } else if (
    now.isBetween(startTime, endTime, "mins", "[]") ||
    startTime.isAfter(now)
  ) {
    return TASK_STATUS.PENDING;
  } else {
    return TASK_STATUS.NOT_COMPLETED;
  }
};

export const getAssignedTo = ({ assignedTo, tasksData, id }) => {
  let isAssignedToAll = assignedTo === ASSIGNED_OPTIONS.ALL.value;
  let isAssignedToStaff = assignedTo === ASSIGNED_OPTIONS.STAFF.value;
  let isAssignedToTeams = assignedTo === ASSIGNED_OPTIONS.TEAMS.value;
  let assignedData = {};
  let taskDetails = tasksData.find((task) => task.id === id);
  if (isAssignedToAll) {
    assignedData = {
      label: "All",
      data: "",
    };
  } else if (isAssignedToStaff) {
    let assigned = [
      ...(taskDetails?.managers || []),
      ...(taskDetails?.staffs || []),
    ];
    assignedData = {
      label: "Staff",
      data: assigned.map(({ name, username }) => name || username).join(", "),
    };
  } else if (isAssignedToTeams) {
    assignedData = {
      label: "Teams",
      data: taskDetails?.teams?.map(({ name }) => name).join(", "),
    };
  }
  return assignedData;
};

export const filterStaff = ({ staffs, staffData, staffType }) => {
  return staffs
    .map((id) => {
      let staff = staffData.find(
        ({ _id, type }) => _id === id && type === staffType
      );
      return staff?.id || null;
    })
    .filter((id) => id);
};

export const parseStaff = ({ staffs, staffData, staffType }) => {
  return staffs
    .map((staffId) => {
      let staff = staffData.find(
        ({ id, type }) => id === staffId && type === staffType
      );
      return staff?._id || null;
    })
    .filter((id) => id);
};
