import { Popover, Transition } from "@headlessui/react";
import { classNames } from "../utils/helpers";

function CustomPopver({
  panelStyles,
  renderPanel = null,
  buttonStyles = "",
  buttonLabel,
  loading = false,
  onOk,
  onCancel,
  message = "Are you sure you want to delete it?",
  okLabel = "Yes",
  cancelLabel = "No",
  disabled = false,
}) {
  return (
    <Popover className="relative">
      <Popover.Button
        className={classNames("mr-2 min-w-[8rem]", buttonStyles)}
        disabled={loading || disabled}
      >
        {buttonLabel}
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className={panelStyles}>
          {({ close }) =>
            renderPanel ? (
              renderPanel(close)
            ) : (
              <div className="p-4">
                <p className="pb-6 text-sm font-bold">{message}</p>
                <div className="flex items-center justify-end">
                  <span
                    className="btn-secondary mr-2 w-16 cursor-pointer text-center"
                    onClick={() => onCancel(close)}
                  >
                    {cancelLabel}
                  </span>
                  <span
                    className="btn-danger ml-2 w-16 cursor-pointer text-center"
                    onClick={() => onOk(close)}
                  >
                    {okLabel}
                  </span>
                </div>
              </div>
            )
          }
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default CustomPopver;
