import { Controller } from "react-hook-form";
import CustomPopver from "../custom-popover";
import InputGroup from "../input-group";
import TagInput from "../tag-input";

export default function StoreDetailsForm({
  onSubmit,
  register,
  loading,
  open,
  errors,
  closeDialog,
  action,
  removeStore = () => {},
  getValues,
  createTag = () => {},
  tagsData = [],
  setValue = () => {},
  control,
}) {
  const editMode = action === "edit";

  return (
    <form onSubmit={onSubmit}>
      <InputGroup
        title="Store Name"
        autoFocus
        htmlFor="name"
        id="name"
        error={open && errors.name}
        placeholder="Enter store name"
        disabled={loading}
        register={() =>
          register("name", {
            required: {
              value: true,
              message: "Name is required",
            },
          })
        }
      />
      <InputGroup
        title="Store Address"
        htmlFor="address"
        id="address"
        error={open && errors.address}
        placeholder="Enter store address"
        disabled={loading}
        register={() =>
          register("address", {
            required: {
              value: true,
              message: "Address is required",
            },
          })
        }
      />
      <InputGroup
        title="Store IP Address"
        htmlFor="ip"
        id="ip"
        error={open && errors.ip}
        placeholder="Enter store IP address"
        disabled={loading}
        register={() =>
          register("ip", {
            required: {
              value: true,
              message: "IP address is required",
            },
            pattern: {
              value:
                /(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/,
              message: "Enter a valid IP address",
            },
          })
        }
      />
      <InputGroup
        title="Store Email"
        htmlFor="email"
        disabled={editMode ? true : loading}
        id="email"
        error={open && errors.email}
        placeholder="Enter store email"
        register={() =>
          register("email", {
            required: {
              value: true,
              message: "Email is required",
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Enter a valid email",
            },
          })
        }
      />
      {/* TODO: enable this field on adding new store as well */}
      {editMode ? (
        <Controller
          name="tags"
          control={control}
          render={() => (
            <div className="pt-3 pb-4">
              <TagInput
                value={getValues("tags")}
                onChange={(value) => {
                  setValue("tags", value);
                }}
                label="Store Tags"
                data={tagsData}
                loading={loading}
                createTag={createTag}
              />
            </div>
          )}
        />
      ) : null}
      <div className="flex">
        <InputGroup
          type="time"
          title="Start Time"
          htmlFor="startTime"
          disabled={loading}
          id="startTime"
          error={open && errors.startTime}
          placeholder="Enter store start time"
          register={() =>
            register("startTime", {
              required: {
                value: true,
                message: "Start time is required",
              },
            })
          }
        />
        <span className="w-20" />
        <InputGroup
          type="time"
          title="End Time"
          htmlFor="endTime"
          id="endTime"
          disabled={loading}
          error={open && errors.endTime}
          placeholder="Enter store end time"
          register={() =>
            register("endTime", {
              required: {
                value: true,
                message: "End time is required",
              },
              validate: {
                greaterThanStartTime: (value) =>
                  value > getValues("startTime") ||
                  "End time must be greater than start time",
              },
            })
          }
        />
      </div>
      <div className="mx-auto flex w-[80%] items-center justify-between pt-4">
        {editMode ? (
          <CustomPopver
            panelStyles="absolute bottom-10 -left-1/2 z-10 w-72 rounded-md bg-white shadow-md"
            buttonLabel="Delete"
            buttonStyles="btn-danger"
            loading={loading}
            onOk={(close) => {
              close();
              removeStore();
            }}
            onCancel={(close) => close()}
          />
        ) : null}
        <button
          className="btn-secondary mx-2 w-32"
          disabled={loading}
          onClick={(e) => {
            closeDialog();
            e.preventDefault();
          }}
        >
          Cancel
        </button>
        <button
          className="btn-primary ml-2 w-32"
          disabled={loading}
          // type="submit"
        >
          {editMode ? "Update" : "Add"}
        </button>
      </div>
    </form>
  );
}
