import { CalendarIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import { classNames } from "../../../utils/helpers";
import FormDialog from "../../dialogs/form-dialog";
import InputGroup from "../../input-group";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

function NoticeDetailsDialog({
  loading,
  setNoticeDetails,
  readNotice,
  forAdminPanel = false,
  ...data
}) {
  const {
    end,
    title,
    description,
    teams,
    readBies,
    createdAt,
    assignedTo,
    stores,
  } = data;

  const [open, setOpen] = useBoolean();
  const [pin, setPin] = useState("");

  useEffect(() => {
    if (data?.id) setOpen.on();
    else {
      setOpen.off();
    }
    // eslint-disable-next-line
  }, [data]);

  const onClose = () => {
    if (!loading) setNoticeDetails(null);
  };

  return (
    <FormDialog
      title={null}
      onClose={onClose}
      open={open}
      disableClose
      modalStyles="p-0 w-full max-w-2xl"
    >
      <div className="flex items-center border-b-[1px] border-black/20 px-6 pb-3 text-xs text-black/40">
        <CalendarIcon className="mr-2 h-5 w-5" />
        <span className="mt-[0.125rem]">
          {dayjs(createdAt).format("DD/MM/YYYY")} - Posted{" "}
          {dayjs(createdAt).fromNow()} - Expires {dayjs(end).fromNow()}
        </span>
      </div>
      <div className="px-6 pt-4 pb-2">
        <h1 title={title} className="mb-2 font-semibold">
          {title}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="notice-card-description text-justify"
        />
        <div className="flex items-center pt-20">
          <span className="mr-4 text-sm font-bold">
            {assignedTo === "all"
              ? "Assigned To:"
              : assignedTo === "stores"
              ? "Stores"
              : "Teams:"}
          </span>
          {assignedTo === "all" ? (
            <span className="mr-3 rounded-full bg-black/10 py-[0.375rem] px-4 text-xs font-semibold">
              All
            </span>
          ) : assignedTo === "stores" ? (
            stores.map((store) => (
              <span className="mr-3 rounded-full bg-black/10 py-[0.375rem] px-4 text-xs font-semibold">
                {store.name}
              </span>
            ))
          ) : teams?.length ? (
            teams.map((team) => (
              <span className="mr-3 rounded-full bg-black/10 py-[0.375rem] px-4 text-xs font-semibold">
                {team.name}
              </span>
            ))
          ) : (
            <span className="text-sm font-bold">-</span>
          )}
        </div>
        <div className="flex items-center pt-3">
          <span className="mr-4 text-sm font-bold">Seen By:</span>
          {readBies?.length ? (
            <div className="flex flex-wrap items-center">
              {readBies.map(({ name, type, id, color, readAt }) => (
                <Tooltip
                  key={id + type}
                  overlay={
                    <p className="text-xs">
                      Read by <strong>{name}</strong> on{" "}
                      <strong>
                        {dayjs(readAt).format("DD MMMM, YYYY hh:mm A")}
                      </strong>
                    </p>
                  }
                  overlayClassName="w-fit"
                  overlayInnerStyle={{ background: "#fff", opacity: 1 }}
                >
                  <span
                    className="my-2 mr-3 cursor-pointer rounded-full py-[0.375rem] px-4 text-xs font-semibold text-white"
                    style={{ background: color || "#000" }}
                  >
                    {name}
                  </span>
                </Tooltip>
              ))}
            </div>
          ) : (
            <span className="text-sm font-bold">-</span>
          )}
        </div>
        <div className="flex items-center justify-between pt-6">
          <button
            className={classNames(
              "btn-secondary w-32",
              forAdminPanel && "ml-auto mb-4"
            )}
            disabled={loading}
            onClick={() => {
              onClose();
            }}
          >
            {forAdminPanel ? "Close" : "Cancel"}
          </button>
          {forAdminPanel ? null : (
            <div className="flex items-center">
              <InputGroup
                title="PIN"
                htmlFor="pin"
                id="pin"
                placeholder="Enter PIN"
                disabled={loading}
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                type="password"
                className="mr-4 w-[150px]"
              />
              <button
                className="btn-primary w-32"
                disabled={loading}
                onClick={() => {
                  readNotice({ pin });
                }}
              >
                Read
              </button>
            </div>
          )}
        </div>
      </div>
    </FormDialog>
  );
}

export default NoticeDetailsDialog;
