import { strapiAPI } from "./api";

export const createStore = async ({ data }) => {
  const response = await strapiAPI.post(`/stores`, { data });
  return response.data;
};

export const edit = async ({ data = {}, method = "PUT", url }) => {
  const response = await strapiAPI.request({
    method,
    url,
    data: { data },
  });
  return response.data;
};

export const createManager = async ({ data }) => {
  const response = await strapiAPI.post(`/custom/create-manager`, { data });
  return response.data;
};

export const updateStore = async ({ data }) => {
  const response = await strapiAPI.put(`/stores/${data.id}`, { data });
  return response.data;
};

export const deleteStore = async (id) => {
  const response = await strapiAPI.delete(`/stores/${id}`);
  return response.data;
};
