import * as React from "react";
import { useQuery } from "react-query";
import useCookie from "react-use-cookie";
import useLocalStorage from "../hooks/useLocalStorage";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, USER_ROLES } from "./constants";
import { showErrorToast } from "./helpers";
import { login } from "./queries";

export const AUTH_STATUS = {
  LOADING: "LOADING",
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
};

export const AuthContext = React.createContext({
  status: AUTH_STATUS.LOADING,
  token: "",
  setToken: () => {},
  user: null,
  setUser: () => {},
  store: null,
});

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useCookie("sop_token", "");
  const [user, setUser] = useLocalStorage("sop_user", null);
  const [status, setStatus] = React.useState(AUTH_STATUS.LOADING);
  const [role, setRole] = React.useState(USER_ROLES.STORE_MEMBER);
  const [data, setData] = React.useState(null);

  const { data: apiData } = useQuery(["/login", { token }], login, {
    retry: false,
    onSuccess: (response) => {
      if (response?.manager) {
        let { manager, ...loginedUser } = response;
        setUser(loginedUser);
        setStatus(AUTH_STATUS.AUTHENTICATED);
        setRole(manager.role);
      } else {
        setUser(null);
        setStatus(AUTH_STATUS.UNAUTHENTICATED);
        setRole(USER_ROLES.STORE_MEMBER);
      }
    },
    onError: showErrorToast,
  });

  React.useEffect(() => {
    setData(apiData);
    // eslint-disable-next-line
  }, [apiData]);

  const logout = async () => {
    setToken("");
    setUser(null);
    //storeops-dev.au.auth0.com/v2/logout?client_id=Ds2gKWRQ1VBvwEkR7jDBBGX9gsZy4HwQ
    window.location.href = `https://${AUTH0_DOMAIN}/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${window.location.origin}&federated`;
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        status,
        logout,
        data: data || {},
        setData,
        role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
