import useStoreDetails from "../hooks/useStoreDetails";

function Container({ children }) {
  const storeData = useStoreDetails();

  if (storeData) return children;

  return (
    <h3 className="title pt-32 pb-16 text-center">No Store Data Found!</h3>
  );
}

export default Container;
