import { registerLicense } from "@syncfusion/ej2-base";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

registerLicense(
  "ORg4AjUWIQA/Gnt2VVhhQlFac1dJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjXH5dcXZVQWBdWE0="
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
