import * as React from "react";

const useBoolean = defaultValue => {
  const [value, setValue] = React.useState(!!defaultValue);

  const on = React.useCallback(() => setValue(true), []);
  const off = React.useCallback(() => setValue(false), []);
  const toggle = React.useCallback(() => setValue(x => !x), []);

  return [value, { on, off, toggle }];
};

export default useBoolean;
