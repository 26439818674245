import { Menu, Transition } from "@headlessui/react";
import * as React from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import StoreopsLogo from "../assets/storeops-logo.jpeg";
import Spinner from "../components/spinner";
import useCheckAuthRoles from "../hooks/useCheckAuthRoles";
import useCurrentPath from "../hooks/useCurrentPath";
import { AUTH_STATUS, useAuth } from "../utils/auth";
import { USER_ROLES } from "../utils/constants";
import { redirectToLogin } from "../utils/helpers";
import { CogIcon, ExternalLinkIcon } from "@heroicons/react/outline";

const AppMenu = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const currentPath = useCurrentPath();

  const isAuthorized = useCheckAuthRoles({
    roles: [USER_ROLES.HEADOFFICE_MANAGER, USER_ROLES.HEADOFFICE_ADMIN],
    checkStoreAdmin: true,
    shouldRedirect: false,
  });

  const { status, user, logout, data, role } = useAuth();
  const { store, username } = data;

  const stores = data?.manager?.stores || [];
  const isHeadofficeAdmin = role === USER_ROLES.HEADOFFICE_ADMIN;

  const menuTitle =
    store && status === AUTH_STATUS.UNAUTHENTICATED
      ? store?.name
      : params?.storeId
      ? stores.find(({ id }) => id === params.storeId)?.name
      : username;

  const showStoreMenuItems = (path = ["/store/:storeId"]) =>
    isAuthorized && path.includes(currentPath);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {menuTitle ? (
        <Menu.Button className="flex items-center rounded py-1 px-2 text-sm font-bold">
          <CogIcon className="mr-2 h-5 w-5" /> {menuTitle}
        </Menu.Button>
      ) : null}
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-1 w-72 origin-top-right divide-y divide-gray-100 rounded bg-white text-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {user && (
            <div className="w-full overflow-x-hidden text-ellipsis whitespace-nowrap py-3 px-3">
              <p className="w-full">{username}</p>
              <small className="w-full  text-gray-400" title={user?.email}>
                {user?.email}
              </small>
            </div>
          )}
          <div className={!isAuthorized ? "" : "p-1"}>
            {showStoreMenuItems(["/store/:storeId/settings"]) && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/store/${params.storeId}`}
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm p-2 text-sm`}
                  >
                    Home
                  </Link>
                )}
              </Menu.Item>
            )}
            {showStoreMenuItems() && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`${pathname}/settings`}
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm p-2 text-sm`}
                  >
                    Store Settings
                  </Link>
                )}
              </Menu.Item>
            )}
            {showStoreMenuItems([
              "/store/:storeId",
              "/store/:storeId/settings",
              "/overview",
            ]) && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/store"
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm p-2 text-sm`}
                  >
                    Dashboard
                  </Link>
                )}
              </Menu.Item>
            )}
            {isHeadofficeAdmin && showStoreMenuItems(["/store"]) && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/overview"
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm p-2 text-sm`}
                  >
                    Overview
                  </Link>
                )}
              </Menu.Item>
            )}
          </div>
          <div className="p-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={`${
                    active ? "bg-gray-100" : ""
                  } group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                  href="mailto:support@storeops.com"
                >
                  Contact support{" "}
                  <ExternalLinkIcon className="ml-2 h-4 w-4 text-slate-500" />
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="p-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={user ? logout : redirectToLogin}
                  className={`${
                    active ? "bg-gray-100" : ""
                  } group flex w-full items-center rounded-sm p-2 text-sm`}
                >
                  {user ? "Logout" : "Login"}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const AppContent = () => {
  const { status } = useAuth();

  if (status === AUTH_STATUS.LOADING) {
    return (
      <div className="block p-4 pt-[30vh]">
        <Spinner className="mx-auto h-10 w-10" />
      </div>
    );
  }

  return <Outlet />;
};

const AppLayout = () => {
  return (
    <div className="min-h-screen">
      <header
        className={`flex h-16 items-center justify-between px-4 shadow-md`}
      >
        <div className="flex items-center">
          <NavLink to="/" className="no-underline">
            <img src={StoreopsLogo} alt="" className="h-10" />
          </NavLink>
        </div>
        <div className="flex items-center">
          <AppMenu />
        </div>
      </header>
      <main>
        <AppContent />
      </main>
    </div>
  );
};

export default AppLayout;
