import { toast } from "react-toastify";
import { API_URL } from "./constants";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const redirectToLogin = () => {
  window.location.href = `${API_URL}/api/connect/auth0`;
};

export const rgbToHex = (r, g, b) =>
  "#" +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

export const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

export const textClassName = (bgHex) => {
  if (!bgHex) {
    return "text-gray-800";
  }
  const bgRgb = hexToRgb(bgHex);

  return (bgRgb[0] * 299 + bgRgb[1] * 587 + bgRgb[2] * 114) / 1000 > 125
    ? "text-gray-800"
    : "text-white";
};

export const textColor = (bgHex) => {
  if (!bgHex) {
    return "rgb(31 41 55)";
  }
  const bgRgb = hexToRgb(bgHex);

  return (bgRgb[0] * 299 + bgRgb[1] * 587 + bgRgb[2] * 114) / 1000 > 125
    ? "rgb(31 41 55)"
    : "white";
};

export const showErrorToast = (error) => {
  let message =
    error?.response?.data?.error?.message || "Whoops! Something went wrong.";
  toast.error(message);
};

export const arrayEquals = (arr1, arr2) => {
  let array2Sorted = arr2.slice().sort();
  return (
    arr1.length === arr2.length &&
    arr1
      .slice()
      .sort()
      .every(function (value, index) {
        return value === array2Sorted[index];
      })
  );
};
