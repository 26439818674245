import axios from "axios";
import { getCookie } from "react-use-cookie";
import { API_URL } from "./constants";
import { redirectToLogin } from "./helpers";

export const strapiAPI = axios.create({
  baseURL: `${API_URL}/api`,
  headers: { "Content-Type": "application/json" },
});

strapiAPI.interceptors.request.use(async (extendedConfig) => {
  const config = Object.assign({}, extendedConfig);

  const accessToken = getCookie("sop_token") || null;

  if (!config.headers.Authorization) {
    config.headers.Authorization = accessToken && `Bearer ${accessToken}`;
  }

  return config;
});

strapiAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error(error);
    if (error && error.response && error.response.status === 401) {
      // const accessToken = getCookie("sop_token") || null;
      // error.config.headers["Authorization"] = `Bearer ${accessToken}`;
      // return strapiAPI.request(error.config);
    } else if (
      error &&
      error.response &&
      error.response.data.error.message === "Unauthenticated"
    ) {
      redirectToLogin();
    } else if (
      error &&
      error.response &&
      (error.response.status === 403 ||
        error.response.data.error.message === "Unauthorized")
    ) {
      // User Authorized but not allowed to access the resource
      window.location.href = "/404";
    }
    return Promise.reject(error);
  }
);
