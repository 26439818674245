import { Dialog, Transition } from "@headlessui/react";
import React, { isValidElement } from "react";
import { classNames } from "../../utils/helpers";

const FormDialog = ({
  open,
  onClose,
  title,
  disableClose = false,
  children,
  modalStyles = null,
}) => {
  const handleClose = () => {
    if (disableClose) return;
    onClose();
  };

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-50"
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the theme contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                "my-8 inline-block transform rounded bg-white p-6 text-left align-middle shadow-xl transition-all",
                modalStyles || "w-full max-w-xl"
              )}
            >
              {title && (
                <Dialog.Title
                  as={isValidElement(title) ? "div" : "h3"}
                  className="flex items-center text-center text-xl leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
              )}
              <div className="mt-4">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FormDialog;
